import React, { lazy, useEffect, useState } from "react";
import { notification } from "src/constants/message";
import Select from "react-select";
import copy from "copy-to-clipboard";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormInput,
  CFormLabel,
  CListGroup,
  CListGroupItem,
  CCallout,
  CBadge,
  COffcanvasTitle,
  CFormTextarea,
} from "@coreui/react";
import { Chart } from "react-google-charts";
import { Outlet } from "react-router-dom";
import { CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";
import CIcon from "@coreui/icons-react";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
  cilLink,
  cilCopy,
} from "@coreui/icons";
import {
  fetchunderprocesslist,
  getgraphleaddata,
  fetchBranchList,
  fetchUsers,
  fetchgoallist,
  fetchAllDashTaskList,
  fetchUserTragetReport,
  fetchUserMonthTragetReport,
  fetchQuestionList,
} from "src/api/common";
import moment from "moment";
import {
  searchLeadCountReport,
  getrevenuedata,
  insertDailyQueAns,
  searchDirectCustomerReport,
  searchLeadAllocateReport,
} from "src/api/commonapi";

import { useGlobalState } from "../pages/GlobalState/globalState.js";
import ReactExport from "react-data-export";

const WidgetsDropdown = lazy(() => import("../widgets/WidgetsDropdown.js"));
const WidgetsBrand = lazy(() => import("../widgets/WidgetsBrand.js"));

const Dashboard = () => {
  let user;
  user = JSON.parse(localStorage.getItem("userData"));
  if (user == null) {
    user = JSON.parse(localStorage.getItem("managerData"));
  }
  const dmodal = JSON.parse(localStorage.getItem("modalData"));
  const dlist = JSON.parse(localStorage.getItem("documentlist"));
  const [visible, setVisible] = useState(false);
  const [tvisible, setTVisible] = useState(false);
  const [underprocesslist, setUnderProcessList] = useState([]);
  const [currentMonthLeadGraphData, setCurrentMonthLeadGraphData] = useState(
    []
  );

  const [goallist, setGoalList] = useState([]);
  const [tasklist, setTaskList] = useState([]);
  const [leaddata, setLeadData] = useState([]);
  const [filterdata, setFilterData] = useState({
    s_date: moment().format("DD-MM-YYYY"),
    e_date: moment().format("DD-MM-YYYY"),
  });
  const [directCustomerGraphData, setDirectCustomerGraphData] = useState([]);
  const [filterCustomerData, setFilterCustomerData] = useState({
    s_date: moment().format("DD-MM-YYYY"),
    e_date: moment().format("DD-MM-YYYY"),
  });

  const [leadAllocateGraphData, setLeadAllocateGraphData] = useState([]);
  const [filterLeadAllocateData, setFilterLeadAllocateData] = useState({
    branch_name: user?.user_type != 0 ? user?.branch_id : 0,
    u_name: user?.user_type == 3 ? user?.u_id : 0, // s_date: moment().format("DD-MM-YYYY"),
    // e_date: moment().format("DD-MM-YYYY"),
  });

  const [branchList, setBranchList] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [revenuefilter, setrevenuefilterdata] = useState({
    branch_name: 0,
    u_name: 0,
  });
  const [ruserdata, setruserdata] = useState([]);
  const [revenuedata, setrevenuedata] = useState([]);
  const [formLink, setFormLink] = useState();
  const [targetfilter, setTargetFilter] = useState({
    branch_name: 0,
    u_id: user?.user_type == 3 ? user?.u_id : 0,
  });
  const [targetReport, setTargetReport] = useState([]);
  const [userprogress, setUserProgress] = useState({});
  const [questionlist, setQuestionList] = useState([]);
  const [qvisible, setQVisible] = useState(false);
  const [queanslist, setQueAnsList] = useState([]);
  const [qcreatedlist, setQCreatedList] = useState([]);
  const [dateWiseLeadExcel, setDateWiseLeadExcel] = useState([]);
  const [targetExcel, setTargetExcel] = useState([]);
  const [directCustomerExcel, setDirectCustomerExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [ws, setWs] = useState(null);
  // const [newMessage, setnewMessage] = useState(null);

  let wss = useGlobalState("ws");

  useEffect(() => {
    wss = wss[0];
    if (wss != null) {
      setWs(wss);
    }
  }, []);

  const getUnderProcessList = async () => {
    setUnderProcessList(
      await fetchunderprocesslist(user?.u_id, user?.branch_id, user?.user_type)
    );
  };

  const getGoalList = async () => {
    setGoalList(await fetchgoallist(user?.u_id));
  };

  const getTaskList = async () => {
    setTaskList(await fetchAllDashTaskList(user?.u_id));
  };

  const targetgoal = async () => {
    const resp = await fetchUserMonthTragetReport(user.u_id);
    setUserProgress(resp);
  };

  const getQuestionList = async () => {
    let nqueanslist = [];
    let qcreated = [];
    const resp = await fetchQuestionList(user?.u_id, 0);
    if (resp?.length != 0) {
      resp &&
        resp?.map((k) => {
          nqueanslist.push({ que: k.question, ans: "" });
          if (qcreated.includes(k?.created_id) == false) {
            qcreated.push(k?.created_id);
          }
        });
    }
    setQueAnsList(nqueanslist);
    setQuestionList(resp);
    setQVisible(true);
    setQCreatedList(qcreated);
  };

  const submitData = async () => {
    let anssssss = "";
    let validation = 0;
    queanslist &&
      queanslist?.map((k, i) => {
        anssssss +=
          "<b>" + (i + 1) + "." + k.que + "</b><br/>&nbsp;" + k.ans + "<br/>";
        if (k.ans == "" || k.ans == undefined) {
          validation = validation + 1;
        }
      });
    if (validation != 0) {
      let notify = notification({
        type: "error",
        message: "Please give answer of all questions",
      });
      notify();
    } else {
      // let fndata = new FormData();
      // fndata.append("sender_id", user.u_id);
      // fndata.append("message", anssssss);
      // fndata.append("receiver_id", JSON.stringify(qcreatedlist));
      // fndata.append("c_type", 0);
      // fndata.append("receiver_type", 0);

      let obj = {
        channel: "INSERT_DAILY_ANSWER",
        data: {
          sender_id: user.u_id,
          sender_name: user?.u_name,
          message: anssssss,
          receiver_id: JSON.stringify(qcreatedlist),
          c_type: 0,
          receiver_type: 0,
          type: 0,
        },
      };

      ws.send(JSON.stringify(obj));

      let socketdata1 = {
        channel: "SEND_CHAT_NOTIFICATION",
        data: {
          u_id: qcreatedlist,
        },
      };
      ws.send(JSON.stringify(socketdata1));

      // const resp = await insertDailyQueAns(fndata);
      // let notify = notification({
      //   type: resp?.status == 0 ? "error" : "success",
      //   message: resp?.message,
      // });
      // notify();
      // if (resp?.status == 1) {
      setQVisible(false);
      if (user?.is_marketing != 1) {
        setVisible(true);
      }
      // }
    }
  };

  useEffect(async () => {
    await filterdataHandle();
    await getdirectCustomerdata();
    await getleadAllocatedata();
    await getUnderProcessList();
    await getGoalList();
    await getTaskList();
    revenuefilterhandle();
    setFormLink(window.location.origin + "/inquiry-form");
    setCityFormLink();
    // if(user?.user_type!=0){
    //   {dmodal!=undefined&&
    //     // setVisible(true);
    //     setTVisible(true);
    //   }
    //   // setTVisible(true);
    // }
    if (user?.user_type != 0 && user?.user_type != 2) {
      if (localStorage.getItem("popState") != new Date().getDate()) {
        setTVisible(true);
        localStorage.setItem("popState", new Date().getDate());
      } else {
        if (user?.is_marketing != 1) {
          if (dlist == 1) {
            setVisible(true);
          }
        }
        targetgoal();
      }
    }

    let newarr = [];
    newarr.push(["Type", "Total User"]);
    const resp = await getgraphleaddata(user?.u_id);
    // console.log("repppp ", resp);
    resp?.map((r) => {
      newarr.push([r.name, r.tlead]);
    });
    // console.log("newarr", newarr);
    setCurrentMonthLeadGraphData(newarr);
    setBranchList(await fetchBranchList());
    if (user?.user_type != 0) {
      getusers(user?.branch_id);
    }
  }, []);

  const getusers = async (branch_id) => {
    setuserdata([]);
    const resp = await fetchUsers(branch_id, user?.u_id);
    setuserdata(resp);

    let nobj = {
      // [0]: {
      //   u_id: 0,
      //   u_name: "All",
      //   user_type: 0,
      // },
    };
    resp?.map((u, i) => {
      nobj = {
        ...nobj,
        [++i]: {
          u_id: u.u_id,
          u_name: u.u_name,
          user_type: u.user_type,
          is_solo_branch: u.is_solo_branch,
        },
      };
    });
    setruserdata(nobj);
  };

  const filterdataHandle = async () => {
    if (
      filterdata.s_date == undefined ||
      filterdata.s_date == "" ||
      filterdata.e_date == undefined ||
      filterdata.e_date == ""
    ) {
      let notify = notification({
        type: "error",
        message: "Please select both date",
      });
      notify();
    } else {
      let fdata = new FormData();
      Object.keys(filterdata)?.map((key) => {
        fdata.append(key, filterdata[key]);
      });
      fdata.append("u_id", user?.u_id);
      fdata.append("branch_id", user.branch_id);
      fdata.append("user_type", user.user_type);
      let newarr = [];
      newarr.push(["Type", "Total User"]);
      const resp = await searchLeadCountReport(fdata);
      // console.log("respppp423423", resp);
      // resp?.data?.map((r) => {
      // console.log("resp?.data", resp?.data);
      // if (r.convert_status == 1) {
      //   newarr.push(["Converted", r.tlead]);
      // } else {
      newarr.push(["Not Converted", resp?.data]);
      newarr.push(["Converted", resp?.tracking_id?.length]);
      // }
      // });
      setLeadData(newarr);
      // console.log("newarrsdfdfsdfsdfsdfsdfsd", newarr);

      // console.log("@@@@@@@@@@");
      let newObj = [];
      resp?.tracking_id?.map((tracking) => {
        newObj = [...newObj, [tracking?.tracking_id]];
      });
      newObj?.push([`Total : ${resp?.tracking_id?.length}`]);

      setDateWiseLeadExcel([
        {
          columns: [{ title: "Converted Lead Data", width: { wpx: 150 } }],
          data: newObj,
        },
      ]);
    }
  };

  const handleFilterClear = async () => {
    let fdata = new FormData();
    // Object.keys(filterdata)?.map((key) => {
    //   fdata.append(key, filterdata[key]);
    // });
    fdata.append("s_date", moment().format("DD-MM-YYYY"));
    fdata.append("e_date", moment().format("DD-MM-YYYY"));
    fdata.append("u_id", user?.u_id);
    fdata.append("branch_id", user.branch_id);
    fdata.append("user_type", user.user_type);

    let newarr = [];
    newarr.push(["Type", "Total User"]);
    const resp = await searchLeadCountReport(fdata);

    resp?.data?.map((r) => {
      if (r.convert_status == 1) {
        newarr.push(["Converted", r.tlead]);
      } else {
        newarr.push(["Not Converted", r.tlead]);
      }
    });
    // console.log(newarr, newarr);

    // console.log("newarr", newarr);
  };

  const getdirectCustomerdata = async () => {
    if (
      filterCustomerData.s_date == undefined ||
      filterCustomerData.s_date == "" ||
      filterCustomerData.e_date == undefined ||
      filterCustomerData.e_date == ""
    ) {
      let notify = notification({
        type: "error",
        message: "Please select both date",
      });
      notify();
    } else {
      let fdata = new FormData();
      Object.keys(filterCustomerData)?.map((key) => {
        fdata.append(key, filterCustomerData[key]);
      });
      fdata.append("u_id", user?.u_id);
      fdata.append("branch_id", user.branch_id);
      fdata.append("user_type", user.user_type);
      let newarr = [];
      newarr.push(["Type", "Total User"]);
      const resp = await searchDirectCustomerReport(fdata);
      // console.log("respppp423423$$$$$$$$$$$", resp);
      let leadCustomerCount = 0;
      let leadCustomerObj = [];
      leadCustomerObj?.push([]);
      leadCustomerObj?.push([`Lead Customer`]);

      let directCustomerCount = 0;
      let directCustomerObj = [];
      directCustomerObj?.push([]);
      directCustomerObj?.push([`Direct Customer`]);

      resp?.data?.map((r) => {
        if (r.l_id == null) {
          // directCustomerCount = directCustomerCount + parseInt(r.tcustomer);
          directCustomerCount = directCustomerCount + 1;
          directCustomerObj = [...directCustomerObj, [r?.tracking_id]];
        } else {
          // leadCustomerCount = leadCustomerCount + parseInt(r.tcustomer);
          leadCustomerCount = leadCustomerCount + 1;
          leadCustomerObj = [...leadCustomerObj, [r?.tracking_id]];
        }
      });

      leadCustomerObj?.push([`Total : ${leadCustomerCount}`]);
      directCustomerObj?.push([`Total : ${directCustomerCount}`]);
      newarr.push(["Direct Customer", directCustomerCount]);
      newarr.push(["Lead Customer", leadCustomerCount]);
      setDirectCustomerGraphData(newarr);
      // console.log("newarrsdfdfsdfsdfsdfsdfsd", newarr);

      setDirectCustomerExcel([
        {
          columns: [{ title: "Converted Lead Data", width: { wpx: 150 } }],
          data: [...directCustomerObj, ...leadCustomerObj],
        },
      ]);
    }
  };

  const directCustomerClear = async () => {
    let fdata = new FormData();
    // Object.keys(filterCustomerData)?.map((key) => {
    //   fdata.append(key, filterCustomerData[key]);
    // });
    fdata.append("s_date", moment().format("DD-MM-YYYY"));
    fdata.append("e_date", moment().format("DD-MM-YYYY"));
    fdata.append("u_id", user?.u_id);
    fdata.append("branch_id", user.branch_id);
    fdata.append("user_type", user.user_type);

    let newarr = [];
    newarr.push(["Type", "Total User"]);
    const resp = await searchDirectCustomerReport(fdata);
    // console.log("respppp423423$$$$$$$$$$$", resp);
    let leadCustomerCount = 0;
    let directCustomerCount = 0;
    resp?.data?.map((r) => {
      if (r.l_id == null) {
        directCustomerCount = directCustomerCount + parseInt(r.tcustomer);
      } else {
        leadCustomerCount = leadCustomerCount + parseInt(r.tcustomer);
      }
    });
    newarr.push(["Direct Customer", directCustomerCount]);
    newarr.push(["Lead Customer", leadCustomerCount]);
    setDirectCustomerGraphData(newarr);
    // console.log("newarrsdfdfsdfsdfsdfsdfsd", newarr);
  };

  const getleadAllocatedata = async () => {
    let fdata = new FormData();
    Object.keys(filterLeadAllocateData)?.map((key) => {
      fdata.append(key, filterLeadAllocateData[key]);
    });
    fdata.append("u_id", user?.u_id);
    fdata.append("ubranch_id", user.branch_id);
    fdata.append("user_type", user.user_type);
    const resp = await searchLeadAllocateReport(fdata);
    // console.log("resppp^&%$&^%&^", resp);
    if (resp?.status == 0) {
      let newarr = [];
      newarr.push(["Month", "Complete"], ["0", 0]);
      setLeadAllocateGraphData(newarr);
    } else {
      let newarr = [];
      newarr.push(["Month", "Allocate"]);

      for (let i = resp.data.length - 1; i >= 0; i--) {
        newarr.push([resp?.data[i]?.month, resp?.data[i]?.count]);
      }
      // console.log("newwwwwwwwwwww", newarr);
      setLeadAllocateGraphData(newarr);
    }
  };

  const leadAllocateClear = async () => {
    let fdata = new FormData();
    fdata.append("u_id", user?.u_id);
    fdata.append("ubranch_id", user.branch_id);
    fdata.append("user_type", user.user_type);
    const resp = await searchLeadAllocateReport(fdata);
    if (resp?.status == 0) {
      let newarr = [];
      newarr.push(["Month", "Complete", "Target"], ["0", 0, 0]);
      setLeadAllocateGraphData(newarr);
    } else {
      let newarr = [];
      newarr.push(["Month", "Allocate"]);

      for (let i = resp.data.length - 1; i >= 0; i--) {
        newarr.push([resp?.data[i]?.month, resp?.data[i]?.count]);
      }
      setLeadAllocateGraphData(newarr);
    }
  };

  const revenuefilterhandle = async () => {
    let fdata = new FormData();
    Object.keys(revenuefilter)?.map((key) => {
      fdata.append(key, revenuefilter[key]);
    });
    fdata.append("u_id", user?.u_id);
    fdata.append("ubranch_id", user.branch_id);
    fdata.append("user_type", user.user_type);

    const resp = await getrevenuedata(fdata);
    if (resp?.status == 0) {
      let newarr = [];
      newarr.push(["Month", "Revenue"], ["0", 0]);
      setrevenuedata(newarr);
    } else {
      let newarr = [];
      newarr.push(["Month", "Revenue"]);
      resp?.data?.map((r) => {
        newarr.push([r.month, r.amount]);
      });
      setrevenuedata(newarr);
    }
  };

  const copydetail = (tracking_id) => {
    copy(tracking_id);
    let notify = notification({
      type: "info",
      message: "Tracking Id Copied",
      position: "top",
    });
    notify();
  };

  const currentMonthLeadOptions = {
    title: "Current Month Lead Report",
    is3D: true,
  };

  const leadAllocateOptions = {
    title: "Lead Allocate Report",
    is3D: true,
  };

  const roptions = {
    title: "Monthly Revenue Report",
    vAxis: { title: "Amount" },
    hAxis: { title: "Month" },
    seriesType: "bars",
    series: { 1: { type: "line" } },
  };

  const optionsl = {
    title: "Date Wise Lead Report",
    is3D: true,
    colors: ["red", "green"],
  };

  const directCustomerOptions = {
    title: "Direct Customer Report",
    is3D: true,
  };

  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const setCityFormLink = async () => {
    // const resp = await fetchcityambassadorbyid(loginUser?.city_id);
    let data = user && user.u_id.toString();
    let buff = new Buffer(data);
    let base64data = buff.toString("base64");
    setFormLink(window.location.origin + "/inquiry-form/" + base64data);
  };
  const openInNewTab = async () => {
    navigator.clipboard.writeText(formLink);
    let notify = notification({
      type: "info",
      message: "Copy to clipboard",
      position: "top",
    });
    await notify();
  };

  useEffect(() => {
    if (user.user_type == 3) {
      targetfilterhandle();
    }
  }, []);

  const copyformlink = () => {
    copy(formLink);
    let notify = notification({
      type: "info",
      message: "Link Copied",
      position: "top",
    });
    notify();
  };

  const handleTargetFilterClear = async () => {
    setTargetReport([]);
    setTargetFilter({
      branch_name: 0,
      u_id: user?.user_type == 3 ? user?.u_id : 0,
    });
  };
  const targetfilterhandle = async () => {
    let abc = targetfilter?.u_id;
    if (user.user_type == 3 && targetfilter.branch_name == 0) {
      abc = user.u_id;
    }
    if (
      targetfilter.branch_name == 0 &&
      targetfilter.u_id == 0 &&
      user.user_type == 0
    ) {
      let notify = notification({
        type: "error",
        message: "Please Select Branch",
      });
      notify();
    } else {
      const resp = await fetchUserTragetReport(abc);
      // console.log("Target Report", resp);
      if (resp?.status == 0) {
        let newarr = [];
        newarr.push(["Month", "Target", "Complete"], ["0", 0, 0]);
        setTargetReport(newarr);
      } else {
        let newarr = [];
        newarr.push(["Month", "Target", "Complete"]);

        for (let i = resp.data.length - 1; i >= 0; i--) {
          newarr.push([
            resp?.data[i]?.month,
            resp?.data[i]?.target != null ? parseInt(resp?.data[i]?.target) : 0,
            resp?.data[i]?.complete,
          ]);
        }
        setTargetReport(newarr);

        let newObj = [];
        resp?.data?.map((mon) => {
          newObj.push([]);
          newObj.push([mon?.month]);
          mon?.tracking_id?.map((tracking) => {
            newObj = [...newObj, [tracking?.tracking_id]];
          });
          newObj.push([`Target : ${mon?.target}   Complete:${mon?.complete}`]);
        });

        setTargetExcel([
          {
            columns: [{ title: "Target Data", width: { wpx: 150 } }],
            data: newObj,
          },
        ]);
        // resp?.data?.map((r) => {
        //   // newarr.push([
        //   //   r.month,
        //   //   r.complete,
        //   //   r.target != null ? parseInt(r.target) : 0,
        //   // ]);
        // });
      }
    }
  };
  const optionscus = {
    colors: ["#db4437", "#4285f4"],
    hAxis: {
      title: "Month",
    },
    vAxis: {
      title: "Target",
    },
    // series: {
    //   1: { curveType: "function" },
    // },
    title: "Target Report",
  };
  return (
    <>
      <CModal
        backdrop="static"
        alignment="center"
        visible={qvisible}
        // onClose={() => {
        //   setQVisible(false);
        //   setVisible(true);
        // }}
        size="lg"
      >
        <CModalBody>
          <CListGroup flush>
            <CListGroupItem>
              <CModalTitle>
                <b>Question - Answer</b>
              </CModalTitle>
              {questionlist.length > 0 ? (
                questionlist &&
                questionlist?.map((b, i) => {
                  return (
                    <>
                      <CCallout color="info">
                        <b>
                          {i + 1}.{b?.question}
                        </b>
                        <br />
                        <CFormTextarea
                          type="text"
                          id={`answer${i}`}
                          name="answer"
                          placeholder="Enter your answer"
                          onChange={(e) => {
                            let nque = queanslist;
                            nque[i].ans = e.target.value;
                            setQueAnsList(nque);
                          }}
                        />
                      </CCallout>
                    </>
                  );
                })
              ) : (
                <CCard className="no_data">
                  <CCardBody>No Questions</CCardBody>
                </CCard>
              )}
            </CListGroupItem>
          </CListGroup>
        </CModalBody>
        <CModalFooter>
          {questionlist.length > 0 ? (
            <CButton color="primary" onClick={submitData}>
              Submit
            </CButton>
          ) : (
            <CButton
              color="secondary"
              className="clearBtn"
              onClick={() => {
                setQVisible(false);
                if (user?.is_marketing != 1) {
                  setVisible(true);
                }
              }}
            >
              Close
            </CButton>
          )}
        </CModalFooter>
      </CModal>
      <CModal
        backdrop="static"
        alignment="center"
        visible={tvisible}
        // onClose={() => {
        //   setTVisible(false);
        //   localStorage.removeItem("modalData");
        //   getQuestionList();
        // }}
        size="lg"
      >
        <CModalBody>
          <CListGroup flush>
            <CListGroupItem>
              <CModalTitle>
                <b>Goals</b>
              </CModalTitle>
              {goallist != ""
                ? goallist &&
                  goallist?.map((b) => {
                    return (
                      <>
                        <CCallout color="info">
                          <b>{b?.g_title}</b>
                          <br />
                          {b?.description}
                        </CCallout>
                      </>
                    );
                  })
                : "No Goal"}
            </CListGroupItem>
            {tasklist != "" && (
              <CListGroupItem>
                <CModalTitle className="my-2">
                  <b>Task</b>
                </CModalTitle>
                <CTable striped hover bordered>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">#</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Subject</CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Start Date
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">End Date</CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Description
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">Priority</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {tasklist &&
                      tasklist?.map((b) => {
                        return (
                          <>
                            <CTableRow>
                              <CTableHeaderCell scope="row">
                                {b?.t_id}
                              </CTableHeaderCell>
                              <CTableDataCell>{b?.subject}</CTableDataCell>
                              <CTableDataCell>{b?.sdate}</CTableDataCell>
                              <CTableDataCell>{b?.edate}</CTableDataCell>
                              <CTableDataCell>{b?.description}</CTableDataCell>
                              <CTableDataCell>
                                {b.priority == 1 ? (
                                  <CBadge color="dark">Low</CBadge>
                                ) : b.priority == 2 ? (
                                  <CBadge color="info">Medium</CBadge>
                                ) : b.priority == 3 ? (
                                  <CBadge color="warning">High</CBadge>
                                ) : (
                                  <CBadge color="danger">Urgent</CBadge>
                                )}
                              </CTableDataCell>
                            </CTableRow>
                          </>
                        );
                      })}
                  </CTableBody>
                </CTable>
              </CListGroupItem>
            )}
          </CListGroup>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            className="clearBtn"
            onClick={() => {
              setTVisible(false);
              localStorage.removeItem("modalData");
              getQuestionList();
            }}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal
        backdrop="static"
        alignment="center"
        visible={visible}
        // onClose={() => {
        //   setVisible(false);
        //   localStorage.removeItem("documentlist");
        // }}
        size="lg"
      >
        <CModalBody>
          <CModalTitle>Customer List- Documents in process</CModalTitle>
          <hr />
          {underprocesslist != null ? (
            <CTable striped hover>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">#</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Customer</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Tracking Id</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Applicant</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Certificate</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {underprocesslist &&
                  underprocesslist?.map((b, index) => {
                    return (
                      <>
                        <CTableRow>
                          <CTableHeaderCell scope="row">
                            {index + 1}
                          </CTableHeaderCell>
                          <CTableDataCell>
                            {b?.customer_prefix + b?.customer_name}
                          </CTableDataCell>
                          <CTableDataCell>
                            {b?.tracking_id}
                            <CIcon
                              title="Copy Tracking Id"
                              style={{
                                color: "blue",
                              }}
                              className="float-end"
                              icon={cilCopy}
                              onClick={() => {
                                copydetail(b?.tracking_id);
                              }}
                            />
                          </CTableDataCell>
                          <CTableDataCell>
                            {b?.a_prefix + b?.a_name}
                          </CTableDataCell>
                          <CTableDataCell>
                            {b?.attestation_certificate}
                          </CTableDataCell>
                          <CTableDataCell>{b?.r_date}</CTableDataCell>
                        </CTableRow>
                      </>
                    );
                  })}
              </CTableBody>
            </CTable>
          ) : (
            "No Documents"
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            className="clearBtn"
            color="secondary"
            onClick={() => {
              setVisible(false);
              localStorage.removeItem("documentlist");
            }}
          >
            Done
          </CButton>
        </CModalFooter>
      </CModal>
      <WidgetsDropdown />
      <CRow>
        <CCard
          className="p-12"
          style={{ padding: "15px", width: "97%", margin: "14px" }}
        >
          <div className="mb-0">
            <COffcanvasTitle>Form Link</COffcanvasTitle>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CIcon
              title="Link"
              className="me-2"
              style={{
                color: "black",
                height: "25px",
                width: "25px",
                cursor: "pointer",
              }}
              icon={cilLink}
              onClick={copyformlink}
            />
            {formLink}
          </div>
        </CCard>
      </CRow>
      {user.user_type != 0 && user.user_type != 2 && (
        <CRow>
          <CCard
            className="p-12"
            style={{ padding: "15px", width: "97%", margin: "14px" }}
          >
            <CRow>
              <CCol md={6}>
                <COffcanvasTitle>Target Goal</COffcanvasTitle>
              </CCol>
              <CCol md={6} className="alignend">
                <COffcanvasTitle>
                  Target{" "}
                  {userprogress?.target != null ? userprogress?.target : 0}
                </COffcanvasTitle>
              </CCol>
            </CRow>

            <ProgressBar
              completed={
                userprogress?.complete != undefined ? userprogress?.complete : 0
              }
              maxCompleted={
                userprogress?.target != null ? userprogress?.target : 0
              }
              height="20px"
              customLabel={
                "Completed " + userprogress?.complete != undefined
                  ? userprogress?.complete
                  : 0
              }
              width="auto"
              margin="20px"
              bgColor="green"
              ariaValuetext="10"
            ></ProgressBar>
            <CRow>
              <CCol md={12} className="alignend">
                <COffcanvasTitle>
                  Completed{" "}
                  {userprogress?.complete != undefined
                    ? userprogress?.complete
                    : 0}
                </COffcanvasTitle>
              </CCol>
            </CRow>
          </CCard>
        </CRow>
      )}
      {localStorage.getItem("flagall") != 1 ? (
        <CCard className="mb-4">
          <CCardBody>
            <CRow className="py-2">
              <div className="mb-0">
                <COffcanvasTitle>Current Month Lead Report</COffcanvasTitle>
              </div>
            </CRow>
            <CRow>
              <Chart
                chartType="PieChart"
                data={currentMonthLeadGraphData}
                options={currentMonthLeadOptions}
                width={"100%"}
                height={"400px"}
              />
            </CRow>
          </CCardBody>
        </CCard>
      ) : (
        ""
      )}
      {localStorage.getItem("flagall") != 1 ? (
        <CCard className="mb-4">
          <CCardBody>
            <CRow className="py-2">
              <div className="mb-0">
                <COffcanvasTitle>Date Wise Lead Report</COffcanvasTitle>
              </div>
            </CRow>
            <CRow>
              <CCol md={4} className="pb-3">
                <CFormLabel>Start Date</CFormLabel>
                <CFormInput
                  value={moment(filterdata?.s_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )}
                  type="date"
                  id="exampleFormControlInput1"
                  name="s_date"
                  onChange={(e) => {
                    setFilterData({
                      ...filterdata,
                      s_date: moment(e.target.value).format("DD-MM-YYYY"),
                    });
                  }}
                />
              </CCol>
              <CCol md={4} className="pb-3">
                <CFormLabel>End Date</CFormLabel>
                <CFormInput
                  value={moment(filterdata?.e_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )}
                  type="date"
                  id="exampleFormControlInput1"
                  name="e_date"
                  onChange={(e) => {
                    setFilterData({
                      ...filterdata,
                      e_date: moment(e.target.value).format("DD-MM-YYYY"),
                    });
                  }}
                />
              </CCol>
            </CRow>
            <CRow>
              {user?.user_type == 0 ? (
                <CCol md={4} className="pb-3">
                  <CFormLabel>Branch Name</CFormLabel>
                  <Select
                    value={branchList?.flatMap((u) => {
                      if (u.branch_id == filterdata.branch_name) {
                        return {
                          value: u.branch_id,
                          label: u.b_name,
                        };
                      } else return null;
                    })}
                    onChange={(e) => {
                      getusers(e.value);
                      setFilterData({
                        ...filterdata,
                        branch_name: e.value,
                        u_name: 0,
                      });
                    }}
                    options={
                      branchList &&
                      branchList?.map((u) => {
                        return {
                          value: u.branch_id,
                          label: u.b_name,
                        };
                      })
                    }
                  />
                </CCol>
              ) : null}
              {user?.user_type == 0 || user?.user_type == 1 ? (
                <CCol md={4} className="pb-3">
                  <CFormLabel>User</CFormLabel>
                  <Select
                    value={
                      userdata &&
                      userdata?.flatMap((u) => {
                        if (u.u_id == filterdata.u_name) {
                          return {
                            value: u.u_id,
                            label: u.u_name,
                          };
                        } else return null;
                      })
                    }
                    onChange={(e) =>
                      setFilterData({
                        ...filterdata,
                        u_name: e.value,
                      })
                    }
                    isDisabled={userdata == null ? "true" : false}
                    options={
                      userdata &&
                      userdata?.map((u) => {
                        return {
                          value: u.u_id,
                          label:
                            u.u_name +
                            (u.user_type == 1
                              ? " (Branch Admin)"
                              : u.user_type == 3
                              ? ` ${
                                  u.is_solo_branch == 1
                                    ? "(Solo Branch)"
                                    : "(Staff)"
                                }`
                              : ""),
                        };
                      })
                    }
                  />
                </CCol>
              ) : (
                ""
              )}
              <CCol md={4} className="mt-4">
                <CButton
                  className="btn-primary"
                  color="dark"
                  onClick={(e) => filterdataHandle()}
                >
                  Search
                </CButton>
                <CButton
                  className="mx-2 clearBtn"
                  color="dark"
                  onClick={async () => {
                    setFilterData({
                      s_date: moment().format("DD-MM-YYYY"),
                      e_date: moment().format("DD-MM-YYYY"),
                    });
                    // await filterdataHandle();
                    handleFilterClear();
                  }}
                >
                  Clear
                </CButton>
                {dateWiseLeadExcel && dateWiseLeadExcel?.length > 0 && (
                  <ExcelFile
                    filename="Date Wise Lead Report"
                    element={
                      <button className="btn btn-primary">Export</button>
                    }
                  >
                    <ExcelSheet
                      dataSet={dateWiseLeadExcel}
                      name="Organization"
                    />
                  </ExcelFile>
                )}
              </CCol>
            </CRow>
            <CRow>
              <Chart
                chartType="PieChart"
                data={leaddata}
                options={optionsl}
                width={"100%"}
                height={"400px"}
              />
            </CRow>
          </CCardBody>
        </CCard>
      ) : (
        ""
      )}

      <CCard className="mb-4">
        <CCardBody>
          <CRow className="py-2">
            <div className="mb-0">
              <COffcanvasTitle>Target Report</COffcanvasTitle>
            </div>
          </CRow>
          <CRow>
            {user?.user_type == 0 ? (
              <CCol md={4} className="pb-3">
                <CFormLabel>Branch Name</CFormLabel>
                <Select
                  value={branchList?.flatMap((u) => {
                    if (u.branch_id == targetfilter?.branch_name) {
                      return {
                        value: u.branch_id,
                        label: u.b_name,
                      };
                    } else return null;
                  })}
                  onChange={(e) => {
                    getusers(e.value);
                    setTargetFilter({
                      ...targetfilter,
                      branch_name: e.value,
                    });
                  }}
                  options={
                    branchList &&
                    branchList?.map((u) => {
                      return {
                        value: u.branch_id,
                        label: u.b_name,
                      };
                    })
                  }
                />
              </CCol>
            ) : null}
            {user?.user_type == 0 || user?.user_type == 1 ? (
              <>
                <CCol md={4}>
                  <CFormLabel>User</CFormLabel>
                  <Select
                    value={
                      ruserdata &&
                      Object.keys(ruserdata)?.map((u) => {
                        if (ruserdata[u].u_id == targetfilter?.u_id) {
                          return {
                            value: ruserdata[u].u_id,
                            label: ruserdata[u].u_name,
                          };
                        } else return null;
                      })
                    }
                    onChange={(e) =>
                      setTargetFilter({
                        ...targetfilter,
                        u_id: e.value,
                      })
                    }
                    isDisabled={ruserdata == null ? "true" : false}
                    options={Object.keys(ruserdata)?.map((u) => {
                      return {
                        value: ruserdata[u].u_id,
                        label:
                          ruserdata[u].u_name +
                          (ruserdata[u].user_type == 1
                            ? " (Branch Admin)"
                            : ruserdata[u].user_type == 3
                            ? ` ${
                                ruserdata[u].is_solo_branch == 1
                                  ? "(Solo Branch)"
                                  : "(Staff)"
                              }`
                            : ""),
                      };
                    })}
                  />
                </CCol>

                <CCol md={4} className="mt-4">
                  <CButton
                    className="btn-primary"
                    color="dark"
                    onClick={(e) => targetfilterhandle()}
                  >
                    Search
                  </CButton>
                  <CButton
                    className="mx-2 clearBtn"
                    color="dark"
                    onClick={handleTargetFilterClear}
                  >
                    Clear
                  </CButton>
                  {targetExcel && targetExcel?.length > 0 && (
                    <ExcelFile
                      filename="Target Report"
                      element={
                        <button className="btn btn-primary">Export</button>
                      }
                    >
                      <ExcelSheet dataSet={targetExcel} name="Organization" />
                    </ExcelFile>
                  )}
                </CCol>
              </>
            ) : null}
          </CRow>
          <CRow>
            <Chart
              chartType="Bar"
              width="100%"
              height="400px"
              data={targetReport}
              options={optionscus}
            />
          </CRow>
        </CCardBody>
      </CCard>

      <CCard className="mb-4">
        <CCardBody>
          <CRow className="py-2">
            <div className="mb-0">
              <COffcanvasTitle>Direct Customer Report</COffcanvasTitle>
            </div>
          </CRow>
          <CRow>
            <CCol md={4} className="pb-3">
              <CFormLabel>Start Date</CFormLabel>
              <CFormInput
                value={moment(filterCustomerData?.s_date, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                )}
                type="date"
                id="exampleFormControlInput1"
                name="s_date"
                onChange={(e) => {
                  setFilterCustomerData({
                    ...filterCustomerData,
                    s_date: moment(e.target.value).format("DD-MM-YYYY"),
                  });
                }}
              />
            </CCol>
            <CCol md={4} className="pb-3">
              <CFormLabel>End Date</CFormLabel>
              <CFormInput
                value={moment(filterCustomerData?.e_date, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                )}
                type="date"
                id="exampleFormControlInput1"
                name="e_date"
                onChange={(e) => {
                  setFilterCustomerData({
                    ...filterCustomerData,
                    e_date: moment(e.target.value).format("DD-MM-YYYY"),
                  });
                }}
              />
            </CCol>
          </CRow>
          <CRow>
            {user?.user_type == 0 ? (
              <CCol md={4} className="pb-3">
                <CFormLabel>Branch Name</CFormLabel>
                <Select
                  value={branchList?.flatMap((u) => {
                    if (u.branch_id == filterCustomerData.branch_name) {
                      return {
                        value: u.branch_id,
                        label: u.b_name,
                      };
                    } else return null;
                  })}
                  onChange={(e) => {
                    getusers(e.value);
                    setFilterCustomerData({
                      ...filterCustomerData,
                      branch_name: e.value,
                      u_name: 0,
                    });
                  }}
                  options={
                    branchList &&
                    branchList?.map((u) => {
                      return {
                        value: u.branch_id,
                        label: u.b_name,
                      };
                    })
                  }
                />
              </CCol>
            ) : null}
            {user?.user_type == 0 || user?.user_type == 1 ? (
              <CCol md={4} className="pb-3">
                <CFormLabel>User</CFormLabel>
                <Select
                  value={
                    userdata &&
                    userdata?.flatMap((u) => {
                      if (u.u_id == filterCustomerData.u_name) {
                        return {
                          value: u.u_id,
                          label: u.u_name,
                        };
                      } else return null;
                    })
                  }
                  onChange={(e) =>
                    setFilterCustomerData({
                      ...filterCustomerData,
                      u_name: e.value,
                    })
                  }
                  isDisabled={userdata == null ? "true" : false}
                  options={
                    userdata &&
                    userdata?.map((u) => {
                      return {
                        value: u.u_id,
                        label:
                          u.u_name +
                          (u.user_type == 1
                            ? " (Branch Admin)"
                            : u.user_type == 3
                            ? ` ${
                                u.is_solo_branch == 1
                                  ? "(Solo Branch)"
                                  : "(Staff)"
                              }`
                            : ""),
                      };
                    })
                  }
                />
              </CCol>
            ) : (
              ""
            )}
            <CCol md={4} className="mt-4">
              <CButton
                className="btn-primary"
                color="dark"
                onClick={(e) => getdirectCustomerdata()}
              >
                Search
              </CButton>
              <CButton
                className="mx-2 clearBtn"
                color="dark"
                onClick={async () => {
                  setFilterCustomerData({
                    s_date: moment().format("DD-MM-YYYY"),
                    e_date: moment().format("DD-MM-YYYY"),
                  });
                  // await filterdataHandle();
                  directCustomerClear();
                }}
              >
                Clear
              </CButton>
              {directCustomerExcel && directCustomerExcel?.length > 0 && (
                <ExcelFile
                  filename="Date Wise Lead Report"
                  element={<button className="btn btn-primary">Export</button>}
                >
                  <ExcelSheet
                    dataSet={directCustomerExcel}
                    name="Organization"
                  />
                </ExcelFile>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CRow>
              <Chart
                chartType="PieChart"
                data={directCustomerGraphData}
                options={currentMonthLeadOptions}
                width={"100%"}
                height={"400px"}
              />
            </CRow>
          </CRow>
        </CCardBody>
      </CCard>

      {user?.is_marketing != 1 && (
        <CCard className="mb-4">
          <CCardBody>
            <CRow className="py-2">
              <div className="mb-0">
                <COffcanvasTitle>Lead Allocated Report</COffcanvasTitle>
              </div>
            </CRow>
            {/* <CRow>
            <CCol md={4} className="pb-3">
              <CFormLabel>Start Date</CFormLabel>
              <CFormInput
                value={moment(filterCustomerData?.s_date, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                )}
                type="date"
                id="exampleFormControlInput1"
                name="s_date"
                onChange={(e) => {
                  setFilterCustomerData({
                    ...filterCustomerData,
                    s_date: moment(e.target.value).format("DD-MM-YYYY"),
                  });
                }}
              />
            </CCol>
            <CCol md={4} className="pb-3">
              <CFormLabel>End Date</CFormLabel>
              <CFormInput
                value={moment(filterCustomerData?.e_date, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                )}
                type="date"
                id="exampleFormControlInput1"
                name="e_date"
                onChange={(e) => {
                  setFilterCustomerData({
                    ...filterCustomerData,
                    e_date: moment(e.target.value).format("DD-MM-YYYY"),
                  });
                }}
              />
            </CCol>
          </CRow> */}
            <CRow>
              {user?.user_type == 0 ? (
                <CCol md={4} className="pb-3">
                  <CFormLabel>Branch Name</CFormLabel>
                  <Select
                    value={branchList?.flatMap((u) => {
                      if (u.branch_id == filterLeadAllocateData.branch_name) {
                        return {
                          value: u.branch_id,
                          label: u.b_name,
                        };
                      } else return null;
                    })}
                    onChange={(e) => {
                      getusers(e.value);
                      setFilterLeadAllocateData({
                        ...filterLeadAllocateData,
                        branch_name: e.value,
                        u_name: 0,
                      });
                    }}
                    options={
                      branchList &&
                      branchList?.map((u) => {
                        return {
                          value: u.branch_id,
                          label: u.b_name,
                        };
                      })
                    }
                  />
                </CCol>
              ) : null}
              {user?.user_type == 0 || user?.user_type == 1 ? (
                <CCol md={4} className="pb-3">
                  <CFormLabel>User</CFormLabel>
                  <Select
                    value={
                      userdata &&
                      userdata?.flatMap((u) => {
                        if (u.u_id == filterLeadAllocateData.u_name) {
                          return {
                            value: u.u_id,
                            label: u.u_name,
                          };
                        } else return null;
                      })
                    }
                    onChange={(e) =>
                      setFilterLeadAllocateData({
                        ...filterLeadAllocateData,
                        u_name: e.value,
                      })
                    }
                    isDisabled={userdata == null ? "true" : false}
                    options={
                      userdata &&
                      userdata?.map((u) => {
                        return {
                          value: u.u_id,
                          label:
                            u.u_name +
                            (u.user_type == 1
                              ? " (Branch Admin)"
                              : u.user_type == 3
                              ? ` ${
                                  u.is_solo_branch == 1
                                    ? "(Solo Branch)"
                                    : "(Staff)"
                                }`
                              : ""),
                        };
                      })
                    }
                  />
                </CCol>
              ) : (
                ""
              )}
              <CCol md={4} className="mt-4">
                <CButton
                  className="btn-primary"
                  color="dark"
                  onClick={(e) => getleadAllocatedata()}
                >
                  Search
                </CButton>
                <CButton
                  className="mx-2 clearBtn"
                  color="dark"
                  onClick={async () => {
                    setFilterCustomerData({
                      // s_date: moment().format("DD-MM-YYYY"),
                      // e_date: moment().format("DD-MM-YYYY"),
                    });
                    // await filterdataHandle();
                    leadAllocateClear();
                  }}
                >
                  Clear
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CRow>
                <Chart
                  chartType="Bar"
                  data={leadAllocateGraphData}
                  options={leadAllocateOptions}
                  width={"100%"}
                  height={"400px"}
                />
              </CRow>
            </CRow>
          </CCardBody>
        </CCard>
      )}

      {user?.user_type == 0 ||
      (user?.user_type == 1 && user?.r_r_permission == 1) ? (
        <CCard className="mb-4">
          <CCardBody>
            <CRow className="py-2">
              <div className="mb-0">
                <COffcanvasTitle>Revenue Report</COffcanvasTitle>
              </div>
            </CRow>
            <CRow>
              {user?.user_type == 0 ? (
                <CCol md={4}>
                  <CFormLabel>Branch Name</CFormLabel>
                  <Select
                    value={branchList?.flatMap((u) => {
                      if (u.branch_id == revenuefilter?.branch_name) {
                        return {
                          value: u.branch_id,
                          label: u.b_name,
                        };
                      } else return null;
                    })}
                    onChange={(e) => {
                      getusers(e.value);
                      setrevenuefilterdata({
                        ...revenuefilter,
                        branch_name: e.value,
                      });
                    }}
                    options={
                      branchList &&
                      branchList?.map((u) => {
                        return {
                          value: u.branch_id,
                          label: u.b_name,
                        };
                      })
                    }
                  />
                </CCol>
              ) : null}
              <CCol md={4}>
                <CFormLabel>User</CFormLabel>
                <Select
                  value={
                    ruserdata &&
                    Object.keys(ruserdata)?.map((u) => {
                      if (ruserdata[u].u_id == revenuefilter?.u_name) {
                        return {
                          value: ruserdata[u].u_id,
                          label: ruserdata[u].u_name,
                        };
                      } else return null;
                    })
                  }
                  onChange={(e) =>
                    setrevenuefilterdata({
                      ...revenuefilter,
                      u_name: e.value,
                    })
                  }
                  isDisabled={ruserdata == null ? "true" : false}
                  options={Object.keys(ruserdata)?.map((u) => {
                    return {
                      value: ruserdata[u].u_id,
                      label:
                        ruserdata[u].u_name +
                        (ruserdata[u].user_type == 1
                          ? " (Branch Admin)"
                          : ruserdata[u].user_type == 3
                          ? ` ${
                              ruserdata[u].is_solo_branch == 1
                                ? "(Solo Branch)"
                                : "(Staff)"
                            }`
                          : ""),
                    };
                  })}
                />
              </CCol>

              <CCol md={4} className="mt-4">
                <CButton
                  className="btn-primary"
                  color="dark"
                  onClick={(e) => revenuefilterhandle()}
                >
                  Search
                </CButton>
                <CButton className="mx-2 clearBtn" color="dark">
                  Clear
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <Chart
                chartType="ComboChart"
                width="100%"
                height="400px"
                data={revenuedata}
                options={roptions}
              />
            </CRow>
          </CCardBody>
        </CCard>
      ) : (
        ""
      )}

      <Outlet />
    </>
  );
};

export default Dashboard;
