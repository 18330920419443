import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "src/constants/message";
import copy from "copy-to-clipboard";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CDropdown,
  CDropdownToggle,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CAvatar,
  CBadge,
} from "@coreui/react";
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
  cilLink,
} from "@coreui/icons";

import CIcon from "@coreui/icons-react";
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from "@coreui/icons";
import { AppBreadcrumb } from "./index";
import { AppHeaderDropdown } from "./header/index";
import { logo } from "src/assets/brand/logo";
import { fetchAllTodayLeadFollowup, fetchUserPermission } from "src/api/common";
import { setGlobalState, useGlobalState } from "../../GlobalState/globalState";
import { Routes } from "src/constants/api_url";

const AppHeader = () => {
  const [getlist, setGetList] = useState([]);
  const [userdata, setUserData] = useState([]);
  const [formLink, setFormLink] = useState();
  const [checksocketcon, setCheckSocketCon] = useState(false);
  const [ws1, setWs] = useState(null);
  // const [checksocketcon, setCheckSocketCon] = useState(false);

  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("customerData"));
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  useEffect(async () => {
    // setGetList(await fetchAllTodayLeadFollowup());
    const resp = await fetchUserPermission(user.created_id);
    setUserData(resp?.data[0]);
    setFormLink(window.location.origin + "/referral-form");
    setCityFormLink();
  }, []);

  const setCityFormLink = async () => {
    // const resp = await fetchcityambassadorbyid(loginUser?.city_id);
    let data = user && user.m_c_id.toString();
    let buff = new Buffer(data);
    let base64data = buff.toString("base64");

    let data1 = user && user.created_id.toString();
    let buff1 = new Buffer(data1);
    let base64data1 = buff1.toString("base64");
    setFormLink(
      window.location.origin +
        "/referral-form/" +
        base64data +
        "/" +
        base64data1
    );
  };

  const messageReceiveHandler = async (event) => {
    let newob = JSON.parse(event.data);
    if (newob.channel == "SEND_MESSAGE") {
      if (location.pathname != "/customer-panel/customerchat") {
        showNotification(newob);
      }
    }
  };

  const ws = useGlobalState("ws");

  const connect = () => {
    const wss = new WebSocket(`${Routes.WEBSOCKET_HOSTNAME}/c_${user.m_c_id}`);
    wss.addEventListener("close", (event) => {
      connect();
    });
    setCheckSocketCon(true);
    setWs(wss);
    setGlobalState("ws", wss);
  };

  if (ws[0] == null) {
    // const wss = new WebSocket(`wss://www.online.measervices.in:8443/c_${user.m_c_id}`);
    // const wss = new WebSocket(`ws://192.168.1.204:8081/c_${user.m_c_id}`);
    // const wss = new WebSocket(`ws://159.65.159.203:8081/s_${user.m_c_id}`);

    // const wss = new WebSocket(`${Routes.WEBSOCKET_HOSTNAME}/c_${user.m_c_id}`);
    // wss.addEventListener("close", (event) => {
    //   console.log("CLOSEDDDDDDDDDDD111111111111111");
    //   const wssnew = new WebSocket(`${Routes.WEBSOCKET_HOSTNAME}/c_${user?.u_id}`);
    //   setTimeout(()=>{
    //     setWs(wssnew);
    //     setGlobalState("ws", wssnew);
    //   },1000)

    // });
    // setGlobalState("ws", wss);
    // console.log("We are connected");
    connect();
  }

  useEffect(() => {
    wss = ws[0];
    if (wss != null && wss?.readyState == 0) {
      // console.log("``````````````");
      wss.addEventListener("message", messageReceiveHandler);
      setGlobalState("ws", wss);
    }
  }, [ws[0]?.readyState]);

  let wss = useGlobalState("ws");
  useEffect(() => {
    // console.log("wssswswswsws", wss);
    wss = wss[0];
    if (wss != null) {
      wss.addEventListener("message", messageReceiveHandler);
      setWs(wss);
    }
  }, [checksocketcon]);

  const showNotification = (newob) => {
    let data = newob.data;
    let options;
    //   {
    //     "message": "hyy",
    //     "sender_id": 1,
    //     "receiver_id": 5,
    //     "c_type": 0,
    //     "receiver_type": 0,
    //     "u_type": 0,
    //     "u_id": 1,
    //     "branch_id": 1
    // }
    if (data.receiver_type == 1) {
      options = {
        body: `${data.message}`,
        // icon: `${themeConfig.app.appLogoImageCollapsedDark}`,
        dir: "ltr",
      };
      var notification = new Notification(`${data.sender_name}`, options);
      notification.onclick = async function (event) {
        await window.focus();
        await window.parent.focus();
        await window.parent.parent.focus();
        navigate("/customer-panel/customerchat", { replace: true });

        // event.notification.close(); // Android needs explicit close.
      };
    }
  };

  if (!("Notification" in window)) {
  } else {
    Notification.requestPermission();
  }

  const copyformlink = () => {
    copy(formLink);
    let notify = notification({
      type: "info",
      message: "Link Copied",
      position: "top",
    });
    notify();
  };

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        {/* <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand> */}
        <CHeaderNav className="d-none d-md-flex me-auto">
          <span className="me-3">
            <b>Name </b> : {userdata.u_name}{" "}
          </span>
          <b>Mobile </b> : {userdata.mobile}
        </CHeaderNav>
        <br></br>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CIcon
            title="Link"
            className="me-2"
            style={{
              color: "black",
              height: "25px",
              width: "25px",
              cursor: "pointer",
            }}
            icon={cilLink}
            onClick={copyformlink}
          />
          {formLink}
        </div>
        <CHeaderNav>
          <CNavItem>
            <CNavLink>
              <CDropdown variant="nav-item">
                <CDropdownToggle
                  placement="bottom-up"
                  className="py-0"
                  caret={false}
                ></CDropdownToggle>
              </CDropdown>
              {/* <button class="btn btn-primary" type="button">
                Notifications
              </button> */}
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CNavItem className="slist">
          <span style={{ fontSize: "20px" }}>Hello , </span>
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            {user?.m_c_name}
          </span>
        </CNavItem>
        <CHeaderNav className="ms-3" aria-label="My Account">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  );
};

export default AppHeader;
