import React, { Suspense } from "react";
import { Navigate, Route, Routes as NewRoutes, Link } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";
// routes config
import routes from "src/routes";

const AppContent = () => {
  return (
    // <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <NewRoutes>
          {routes?.map((rout, idx) => {
            return (
              rout.component && (
                <Route
                  key={idx}
                  path={rout.path}
                  exact={rout.exact}
                  name={rout.name}
                  element={<rout.component />}
                />
              )
            );
          })}
          <Route path="/" element={<Navigate to="/customer-panel/myaccount" />} />
        </NewRoutes>
      </Suspense>
    // </CContainer>
  );
};

export default React.memo(AppContent);
