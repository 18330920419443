import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { notification } from "src/constants/message";
import Select from "react-select";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cibHipchat,
  cilApple,
  cilCursor,
  cilFlagAlt,
  cilPen,
  cilTrash,
  cilUserFollow,
  cilRecycle,
  cilFile,
  cilCopy,
  cilPrint,
  cilContact,
  cilEnvelopeClosed,
} from "@coreui/icons";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
  COffcanvasTitle,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CCardHeader,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCardGroup,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import { fetchUserById } from "src/api/common";
import { fetchSetting } from "src/api/common";
import { insertLead } from "src/api/commonapi";

import "./referralform.css";
import { Routes } from "src/constants/api_url";
import { useGlobalState } from "src/views/pages/GlobalState/globalState";
import CustomImage from "src/views/CommonFunctions/CustomImage";
//-----------------------------------------------------------------------

const ReferralForm = () => {
  // const [ws, setWs] = useState(null);
  let { cid } = useParams();
  let { crid } = useParams();
  let created_id = 0;
  let allocated_id = 0;
  let p_u_id = 0;
  let u_id = 0;
  let date = moment().format("DD-MM-YYYY");
  if (cid != undefined) {
    created_id = Buffer.from(cid, "base64").toString("ascii");
    // console.log("created_id", created_id);
  }
  if (crid != undefined) {
    allocated_id = Buffer.from(crid, "base64").toString("ascii");
    // console.log("allocated_id", allocated_id);
  }

  const [userData, setUserData] = useState([]);
  const [formdata, setformdata] = useState({});
  const [c_url, setc_url] = useState({});
  const [sresp, setresp] = useState(0);
  const [step, setStep] = useState(0);
  const [submitResponse, setSubmitResponse] = useState(0);

  let prefixoption = [
    { value: 3, label: "Dr." },
    { value: 4, label: "M/S" },
    { value: 5, label: "Master" },
    { value: 2, label: "Miss." },
    { value: 0, label: "Mr." },
    { value: 1, label: "Mrs." },
    { value: 6, label: ":" },
  ];

  let serviceOption = [
    { value: 10, label: "Attestation" },
    { value: 17, label: "Apostille" },
    { value: 20, label: "Translation" }, //translation
    { value: 8, label: "Visa" },
  ];

  useEffect(async () => {
    // let wss = new WebSocket(`wss://www.online.measervices.in:8443/s_${allocated_id}`);
    // let wss = new WebSocket(`ws://192.168.1.204:8081/s_${allocated_id}`);
    // let wss = new WebSocket(`ws://159.65.159.203:8081/s_${allocated_id}`);

    // let wss = new WebSocket(`${Routes.WEBSOCKET_HOSTNAME}/s_${allocated_id}`);
    // if (wss != null) {
    //   setWs(wss);
    // }

    const gresp = await fetchSetting();
    // console.log(gresp, "gresp");
    setc_url(gresp?.c_url);
  }, []);

  useEffect(async () => {
    let resp = await fetchUserById(allocated_id);
    setStep(0);
    // console.log("resp", resp);
    setformdata({
      l_date: date,
      priority: 2,
      l_source: 282,
      l_status: 1,
      l_branch_id: resp?.branch_id,
      l_created_branch_id: resp?.branch_id,
      l_created_by: allocated_id,
      l_allocate_to: allocated_id,
      reference_id: created_id,
      l_name: "",
      co_no: "",
      l_email: "",
      l_address: "",
      l_city: "",
      l_service: 0,
      l_name_prefix: -1,
    });
  }, [sresp]);

  const submitData = async (e) => {
    if (
      formdata.l_name == undefined ||
      formdata.l_name == "" ||
      formdata.co_no == undefined ||
      formdata.co_no == "" ||
      formdata.l_email == undefined ||
      formdata.l_email == "" ||
      formdata.l_city == undefined ||
      formdata.l_city == "" ||
      formdata.l_service == undefined ||
      formdata.l_service == 0 ||
      formdata.l_service == undefined ||
      formdata.l_service == -1
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill All Data",
      });
      notify();
    } else {
      setStep(1);

      const resp = await insertLead(JSON.stringify(formdata));
      if (resp.status == 0 || resp.status == 2) {
        let notify = notification({
          type: "error",
          message: resp.message,
        });
        notify();
        setStep(0);
      }
      if (resp.status == 1) {
        setSubmitResponse(1);
        setStep(0);
        setformdata({});
        setTimeout(() => {
          if (c_url != undefined && c_url != null && c_url != "") {
            window.location.replace(`${c_url}`);
          }
        }, 3000);

        setresp(sresp + 1);
      }

      // let socketdata = {
      //   channel: "SEND_NEWLEAD",
      //   data: {
      //     id: allocated_id,
      //   },
      // };
      // console.log("socketdata",socketdata);
      // ws.send(JSON.stringify(socketdata));

      // socketdata = {
      //   channel: "SEND_NEWLEAD",
      //   data: {
      //     id: 1,
      //   },
      // };
      // ws.send(JSON.stringify(socketdata));
    }
  };
  return (
    <>
      <div className="d-flex flex-row">
        <CContainer>
          {submitResponse == 0 ? (
            <>
              <div className="center-hor-ver">
                <div className="form-container-cus bg-white my-5">
                  <div className="row">
                    <CCol md={12} style={{ textAlign: "center" }}>
                      <div className="mb-3">
                        <CustomImage
                          src={`${Routes.API_HOSTNAME}/images/adminlogo/loginlogo.png`}
                          className="cus_form_logo"
                        />

                        <h5
                          className="form_title"
                          style={{ margin: "15px 0 ", textAlign: "center" }}
                        >
                          ONLINE APOSTILLE & ATTESTATION FORM
                        </h5>
                      </div>
                    </CCol>
                  </div>
                  <CForm>
                    <div className="mb-3 row">
                      <CFormLabel htmlFor="exampleFormControlInput1">
                        Name
                      </CFormLabel>

                      <CCol md={4} className="mob_mar_10">
                        <Select
                          value={
                            prefixoption?.flatMap((u) => {
                              if (u.label == formdata?.l_name_prefix) {
                                return {
                                  value: u.value,
                                  label: u.label,
                                };
                              } else return null;
                            })
                            // .filter((n) => n)[0]
                          }
                          onChange={(e) => {
                            setformdata({
                              ...formdata,
                              l_name_prefix: e.label,
                            });
                          }}
                          options={prefixoption}
                        />
                      </CCol>
                      <CCol md={8} className="mob_mar_10">
                        <CFormInput
                          type="text"
                          value={formdata?.l_name}
                          id="exampleFormControlInput1"
                          name="l_name"
                          placeholder="Enter Name"
                          onChange={(e) => {
                            setformdata({
                              ...formdata,
                              l_name: e.target.value,
                            });
                          }}
                        />
                      </CCol>
                    </div>

                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">
                        Contact Number
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        value={formdata?.co_no}
                        id="exampleFormControlInput1"
                        name="co_no"
                        placeholder="Enter Contact Number"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            co_no: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">
                        Email
                      </CFormLabel>
                      <CFormInput
                        type="email"
                        value={formdata?.l_email}
                        id="exampleFormControlInput1"
                        name="l_email"
                        placeholder="Enter Email Address"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_email: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">
                        City
                      </CFormLabel>
                      <CFormInput
                        value={formdata?.l_city}
                        type="text"
                        id="exampleFormControlInput1"
                        name="l_city"
                        placeholder="Enter City"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_city: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">
                        Message
                      </CFormLabel>
                      <CFormTextarea
                        value={formdata?.l_message}
                        type="text"
                        id="exampleFormControlInput1"
                        name="l_message"
                        placeholder="Enter Message"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_message: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">
                        Service
                      </CFormLabel>

                      <Select
                        value={
                          serviceOption?.flatMap((u) => {
                            if (u.value == formdata?.l_service) {
                              return {
                                value: u.value,
                                label: u.label,
                              };
                            } else return null;
                          })
                          // .filter((n) => n)[0]
                        }
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_service: e.value,
                          });
                        }}
                        options={serviceOption}
                      />
                    </div>

                    <CCol style={{ textAlign: "center" }}>
                      <CButton
                        disabled={step == 0 ? false : true}
                        color="primary"
                        style={{ marginBottom: "25px" }}
                        onClick={submitData}
                      >
                        Submit
                      </CButton>
                    </CCol>
                  </CForm>
                </div>
              </div>
            </>
          ) : (
            <>
              <CRow className="justify-content-center">
                <CCol md={5} style={{ width: "600px" }}>
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <>
                          <CForm
                            className="dsgrid"
                            // noValidate
                            // validated={validated}
                            // onSubmit={handleSubmit}
                            // onSubmit={handleSubmit(onSubmit)}
                          >
                            <CustomImage
                              src={`${Routes.API_HOSTNAME}/images/adminlogo/check.png`}
                              className="mb-3 center_img"
                            />
                            <h2 className="text-center mb-3">Thank You !</h2>
                            <h5 className="text-center mb-3">
                              Your submission has been sent.
                            </h5>
                            <a
                              style={{ cursor: "pointer" }}
                              className="text-center"
                              color="link"
                              onClick={() => {
                                setSubmitResponse(0);
                              }}
                            >
                              Go Back
                            </a>
                          </CForm>
                        </>
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </>
          )}
        </CContainer>
      </div>
    </>
  );
};

export default ReferralForm;
