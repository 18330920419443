import React, { useEffect, useState } from "react";
import "simplebar/dist/simplebar.min.css";
import { Routes } from "src/constants/api_url";
import axios from "axios";
import { CAvatar } from "@coreui/react";

const CustomImage = ({ src, isAvatar, ...others }) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(src, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${user?.auth_token || "public"}`,
          },
        });
        const imageData = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        const base64ImageSrc = `data:${response.headers["content-type"]};base64,${imageData}`;
        setImageSrc(base64ImageSrc);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [src]);

  return (
    <>
      {imageSrc && isAvatar ? (
        <CAvatar src={imageSrc} {...others} />
      ) : (
        <img src={imageSrc} {...others} />
      )}
    </>
  );
};

export default CustomImage;
