import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { fetchAttributesbyid, fetchSetting } from "src/api/common";
import { pickupSendingBySelf } from "src/api/commonapi";
import { Routes } from "src/constants/api_url";
import { notification } from "src/constants/message";
import CustomImage from "src/views/CommonFunctions/CustomImage";
import { compareById } from "src/views/CommonFunctions/common";

const PickupDoneForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const defaultSendingBySelfData = {
    p_i_note: "",
    courier_id: 0,
    tracking_no: "",
  };
  const [sentBySelfData, setSendingBySelfData] = useState(
    defaultSendingBySelfData
  );
  const [companyName, setCompanyName] = useState([]);
  const [submitResponse, setSubmitResponse] = useState(0);
  const [signature, setSignature] = useState("");
  const [step, setStep] = useState(0);
  const [c_url, setC_url] = useState({});

  useEffect(async () => {
    const resp = await fetchSetting();
    setC_url(resp?.c_url);
  }, []);

  useEffect(async () => {
    setStep(0);
    let courierCompanyResponse = await fetchAttributesbyid(16);
    setCompanyName(courierCompanyResponse?.sort(compareById));
  }, []);

  const pickupSendingBySelfFunction = async () => {
    let fdata = new FormData();
    if (
      sentBySelfData.courier_id == undefined ||
      sentBySelfData.courier_id == 0
    ) {
      let notify = notification({
        type: "error",
        message: "Please Select Courier Company",
      });
      notify();
    } else {
      setStep(1);
      Object.keys(sentBySelfData)?.map((key) => {
        fdata.append(key, sentBySelfData[key]);
      });
      const plain = Buffer.from(code, "base64").toString("utf8");
      // console.log(plain, "plain");
      fdata.append("sending_user", 1);
      fdata.append("lead_id", parseInt(plain?.split("_")[0]));
      const resp = await pickupSendingBySelf(fdata);
      let notify = notification({
        type: resp.status == 0 ? "error" : "success",
        message: resp.message,
      });
      if (resp.status == 0 || resp.status == 2) {
        setStep(0);
      }
      notify();
      if (resp.status == 1) {
        setSignature(resp?.data);
        setSubmitResponse(1);
        setStep(0);
        setSendingBySelfData(defaultSendingBySelfData);
        setTimeout(() => {
          if (c_url != undefined && c_url != null && c_url != "") {
            window.location.replace(`${c_url}`);
          }
        }, 5000);
      }
    }
  };

  return (
    <>
      <div className="d-flex flex-row">
        <CContainer>
          {submitResponse == 0 ? (
            <>
              <div className="center-hor-ver">
                <div className="form-container-cus bg-white my-5">
                  <CRow>
                    <CCol md={12} className="text-center m-1">
                      <CustomImage
                        src={`${Routes.API_HOSTNAME}/images/adminlogo/loginlogo.png`}
                        className="cus_form_logo"
                      />
                      <h5
                        className="form_title text-center"
                        style={{ color: "black" }}
                      >
                        Please submit the form to confirm the envelope you have
                        Sent
                      </h5>
                    </CCol>
                    <CCol>
                      <CForm>
                        <div className="mb-3 mt-3">
                          <CFormLabel
                            className="mb-1"
                            style={{ color: "black" }}
                          >
                            Attach your Courier Slip :
                          </CFormLabel>
                          <CFormInput
                            type="file"
                            id="p_o_attachment"
                            name="p_o_attachment"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(event) => {
                              setSendingBySelfData({
                                ...sentBySelfData,
                                p_o_attachment: event.currentTarget.files[0],
                              });
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <CFormLabel
                            className="mb-1"
                            style={{ color: "black" }}
                          >
                            Courier Company Name :
                          </CFormLabel>
                          <Select
                            placeholder="Select Courier Company"
                            value={companyName?.map((u) => {
                              if (u.attribute_id == sentBySelfData.courier_id) {
                                return {
                                  value: u.attribute_id,
                                  label: u.name,
                                };
                              } else return null;
                            })}
                            options={
                              companyName &&
                              companyName?.map((ls) => {
                                return {
                                  value: ls.attribute_id,
                                  label: ls.name,
                                };
                              })
                            }
                            onChange={(e) => {
                              setSendingBySelfData({
                                ...sentBySelfData,
                                courier_id: e.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <CFormLabel
                            className="mb-1"
                            style={{ color: "black" }}
                          >
                            Courier Tracking Number :
                          </CFormLabel>
                          <CFormInput
                            value={sentBySelfData?.tracking_no}
                            name="tracking_id"
                            placeholder="Enter Courier Tracking Number"
                            onChange={(e) => {
                              setSendingBySelfData({
                                ...sentBySelfData,
                                tracking_no: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <CFormLabel
                            className="mb-1"
                            style={{ color: "black" }}
                          >
                            If any Additional Instruction
                            <span> (Optional)</span> :
                          </CFormLabel>
                          <CFormTextarea
                            type="text"
                            id="p_i_note"
                            name="p_i_note"
                            placeholder="Enter Instruction"
                            value={sentBySelfData?.p_i_note}
                            onChange={(e) => {
                              setSendingBySelfData({
                                ...sentBySelfData,
                                p_i_note: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <CCol className="mb-3" style={{ textAlign: "center" }}>
                          <CButton
                            disabled={step == 0 ? false : true}
                            color="primary"
                            onClick={() => {
                              pickupSendingBySelfFunction();
                            }}
                          >
                            Submit
                          </CButton>
                        </CCol>
                      </CForm>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </>
          ) : (
            <>
              <CRow className="justify-content-center">
                <CCol md={5} style={{ width: "600px" }}>
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <>
                          <CForm className="dsgrid">
                            <CustomImage
                              src={`${Routes.API_HOSTNAME}/images/adminlogo/check.png`}
                              className="mb-3 center_img"
                            />
                            <h5
                              className="text-center mb-3"
                              style={{ color: "#000" }}
                            >
                              We have noted the completion of the Pickup.
                            </h5>
                            <h5
                              className="text-center mb-3"
                              style={{ color: "#000" }}
                            >
                              We will update you as soon as the envelope is
                              delivered to our office.
                            </h5>
                            <h2
                              className="text-center mb-3"
                              style={{ color: "#000" }}
                            >
                              Thank You
                            </h2>
                            {signature != "" && (
                              <h5
                                className="text-center mb-3"
                                style={{ color: "#000" }}
                              >
                                {signature}
                              </h5>
                            )}
                          </CForm>
                        </>
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </>
          )}
        </CContainer>
      </div>
    </>
  );
};

export default PickupDoneForm;
