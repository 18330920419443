import { useDispatch } from "react-redux";
import { StoreAction } from "src/constants";

export const setUserData = async (dispatch, user_data) => {
  try {
    await dispatch({ type: StoreAction.SET_USER_DATA, data: user_data });
  } catch (e) {
    console.log(e);
  }
};

export const setUserPermission = async (dispatch, user_permission) => {
  try {
    await dispatch({
      type: StoreAction.SET_USER_PERMISSION,
      data: user_permission,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setTemplatePermission = async (dispatch, tpermission) => {
  try {
    await dispatch({
      type: StoreAction.SET_TEMPLATE_PERMISSION,
      data: tpermission,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setFollowupCounter = async (dispatch, tfcount) => {
  try {
    await dispatch({
      type: StoreAction.SET_TOTAL_FOLLOWUP,
      data: tfcount,
    });
  } catch (e) {
    console.log(e);
  }
};
