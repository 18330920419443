import Axios from "axios";
import { Routes } from "../constants/api_url";
import CryptoJS from "crypto-js";

export const axios = Axios.create({
  // rejectUnauthorized: false, // (NOTE: this will disable client verification)
  baseURL: Routes.API_HOSTNAME_WP,
  timeout: 1000000000,
  responseType: "json",
});
const endPoints = ["remaining-credit-api"];
axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json , */*",
      "Content-Type": "application/json",

      Authorization: config.data.Authorization,
      type: "web",
    };
    if (!endPoints.includes(config.url)) {
      config.data = {
        data: CryptoJS.AES.encrypt(
          JSON.stringify(config.data),
          "my-secret-key@12"
        ).toString(),
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (!endPoints.includes(response.request.responseURL.split("/")[3])) {
      var bytes = CryptoJS.AES.decrypt(response?.data.data, "my-secret-key@12");
      var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      response.data.data = JSON.parse(decryptedData);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
