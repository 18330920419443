import React, { useState } from "react";
import { Link } from "react-router-dom";
import { notification } from "src/constants/message";
import { Routes } from "src/constants/api_url";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormFeedback,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { CustomerLoginRequest } from "src/api/auth";
import { useDispatch } from "react-redux";
import {
  setCustomerData,
  setUserPermission,
} from "src/store/actions/commonActions";
import { useNavigate } from "react-router-dom";
import CustomImage from "src/views/CommonFunctions/CustomImage";

const CustomerLogin = () => {
  const navigate = useNavigate();
  const [userdata, setUserDatastate] = useState({});
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const changeuserdata = (e) => {
    setUserDatastate({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const jslogindata = JSON.stringify(userdata);
      const response = await CustomerLoginRequest(jslogindata);

      if (response.status === 0) {
        let notify = notification({
          type: response.status == 0 ? "error" : "success",
          message: response.message,
        });
        notify();
      } else {
        // localStorage.setItem("customerData", JSON.stringify(response?.data));

        localStorage.setItem("customerData", JSON.stringify(response?.data));

        navigate("/customer-panel/myaccount", { replace: true });
        window.location.reload();
        // localStorage.setItem("modalData", 1);
        // setCustomerData(dispatch, response?.data);
        // var permission={};
        // response.permission?.map((e)=>{
        //   permission={
        //     ...permission,
        //     [e.f_id]:{
        //       create:e.creates,
        //       delete:e.deletes,
        //       edit:e.edits,
        //       view:e.views,
        //     }
        //   }
        // });
        // setUserPermission(dispatch,permission);
      }
    }
    setValidated(true);
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5} style={{ width: "600px" }}>
            <CustomImage
              src={`${Routes.API_HOSTNAME}/images/adminlogo/loginlogo.png`}
              className="mb-3 center_img"
            />
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <h2 className="text-center mb-5">Login</h2>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        onChange={changeuserdata}
                        required
                      />
                      <CFormFeedback invalid>
                        Please Enter a Valid Email.
                      </CFormFeedback>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        onChange={changeuserdata}
                        required
                      />
                      <CFormFeedback invalid>
                        Please Enter a Password.
                      </CFormFeedback>
                    </CInputGroup>
                    <CRow>
                      <CCol xs={7}>
                        <CButton color="primary" className="px-4" type="submit">
                          Login
                        </CButton>
                      </CCol>
                      {/* <CCol xs={5} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot Password?
                        </CButton>
                        <Link
                          className="px-0"
                          color="link"
                          to="/forgotpassword"
                        >
                          Forgot Password
                        </Link>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default CustomerLogin;
