import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'
import { useSelector } from "react-redux";
// import  permission  from "../Permission/permission.js";

export const AppSidebarNav = ({ items }) => {
  let user = JSON.parse(localStorage.getItem("userData"));
  const location = useLocation()
  const navLink = (name, icon, badge,f_id) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      <Component
        {...(rest.to &&
          !rest.items &&{
            component: NavLink,
            activeclassname: 'active',
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item?.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }
  // const { user_permission } = useSelector((state) => state.common);
  // const insertIf = (f_id) => {
  //   let v=false;
  //   if(user.user_type==0)
  //   {
  //     v=true;
  //   }
  //   else
  //   {
  //     if (user_permission && Object.keys(user_permission).length != 0) {
  //       Object.keys(user_permission)?.map((key)=>{
  //         if(key==f_id && user_permission[key].view==1)
  //         {
  //           v=true;
  //         }
  //       });
  //     }
  //   }
  //   return v==true ? true : false;
  // }

  return (
    <React.Fragment>
      {items && 
        items?.map((item, index) =>(
          item.items ? navGroup(item, index) :
          //  (permission(item.f_id)?navItem(item, index):
          //  '')
          navItem(item, index)
        ))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
