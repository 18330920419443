import {
  getAttributes,
  getBranch,
  getServicePrice,
  getTypeCountry,
  getFeature,
  getLeadSource,
  getAllLead,
  getAllBranch,
  getUsers,
  getLeadStatus,
  getSetting,
  getFollowUp,
  getAllSMSTemlate,
  //sakshi
  getAllRoleList,
  getRolePermission,
  getuserbyparentuser,
  getUserPermission,
  getusersBytype,
  getmanagerallocationlist,
  getbranchallocatedlist,
  fetchInvoiceDatabycid,
  getbranchdatabyid,
  fetchgraphleaddata,
  fetchMangerwiseBranch,
  fetchUserByBranch,
  gettemplatelistbycate,
  gettemplatedata,
  getAllProcessOverdue,
  getFAQcatepermission,
  //JIlsir
  getTemplateCategory,
  getTemplatesubCategory,
  getEmailTemplate,
  getBranchData,
  gettodayleadfollowup,
  //Task
  getAllTask,
  getFeedback,
  getcountAllTask,
  getcountAllLead,
  getcountAllTodayLead,
  getCustomerList,
  getCustomerbyid,
  getAttributesbyid,
  //Visa
  getAllVisaList,
  //change
  getVisabyid,
  //Attestation
  getAllAttestationList,
  //Apostille
  getAllApostilleList,
  getApostillebyid,
  //Customer Comment
  getcomment,
  //Customer Files
  getAllFiles,
  //payment
  getPayment,
  getPaymentList,
  getRefundAmt,
  //Refund
  getPaymentDetails,
  getAllRefunddata,
  getsettingdata,
  //cashflow
  getBranchUser,
  getCashPayment,
  getAllUnderProcesslist,
  //TDS
  getTDSList,
  getAttestationbyid,
  //coverletter
  getBusinessSummary,
  getBranchCustomer,
  //Refund-Paymentreceipt
  getRefundbyid,
  getPaymentDatabyid,
  getcountFollowUp,
  //Proforma Invoice Data
  fetchProformaInvoiceDatabylid,
  getAllEmailTemplate,
  //Translation
  getAllTranslationList,
  getTranslationbyid,

  //////////////////////// customer ///////////////////////////////
  getCustomers,
  getAttestations,
  getApostilles,
  //translationcust
  getTranslations,
  getVisas,
  getPayments,
  getcustomerTDSList,
  getCustomerFeedbacks,
  //Goal
  getAllGoal,
  //Goal And Task On Dashboard
  getAllGoallist,
  getAllDashTask,
  getTaskUsers,
  getactivitylogbyid,
  getLoginActivityData,
  getallholdapplication,
  getnewTask,
  getUserById,
  getFaqCategory,
  getFaq,
  getUserTaragetReport,
  getTarget,
  getDraftReceiptList,
  getDraftPaymentDatabyid,
  //Suggestion
  getAllSuggestion,
  getUserMonthTaragetReport,
  getAllmaincustomer,
  getEmbassyFeeList,
  getallnewlead,
  getAllnewCustomerofAnotherBranch,
  getalldraftreceipt,
  getAllapplicants,
  getAllInPickupList,
  getAllOutPickupList,
  getAllPickupDoneList,
  getAllPickupDoneLeadList,
  getallquestion,
  getAllQuestionlist,
  getuserchat,
  getallcustomerchat,
  getCustomerUser,
  gettemplatebody,
  getEmailHistory,
  getDraftReceiptCounter,
  getservicevalue,
  getBankDetails,
  getservicevalueType,
  getreferralcustomerlist,
  getAttestationListBycid,
  getleadfollowup,
  getUserList,
  getallchat,
  gettodaycustomerfollowup,
  getcountCFollowUp,
  getApostilleListBycid,
  getTranslationListBycid,
  getVisaListBycid,
  getAllCashFlow,
  getAllDispatchingApplications,
  getCourierChargeList,
} from "./commonapi";

//-----------------------------------------------------

export async function fetchAttributesList(type, page, perPage) {
  try {
    const resp = await getAttributes(type, page, perPage);
    if (resp.status == 1) return resp.aData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchBranchList() {
  try {
    const resp = await getBranch();
    if (resp.status == 1) return resp.bData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchLoginActivityData(page, perPage) {
  try {
    const resp = await getLoginActivityData(page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchTypeCountryList(type, country) {
  try {
    const resp = await getTypeCountry(type, country);
    if (resp.status == 1) return resp.Data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchServicePriceList(page, perPage) {
  try {
    const resp = await getServicePrice(page, perPage);
    if (resp.status == 1) return resp.sData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchFeaturesList() {
  try {
    const resp = await getFeature();
    if (resp.status == 1) return resp.fData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchLeadSourceList() {
  try {
    const resp = await getLeadSource();
    if (resp.status == 1) return resp.lsData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllLeadList(u_id, page, perPage) {
  try {
    const resp = await getAllLead(u_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllBranch() {
  try {
    const resp = await getAllBranch();
    if (resp.status == 1) return resp.bData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUsers(branch_id, u_id) {
  try {
    const resp = await getUsers(branch_id, u_id);
    if (resp.status == 1) return resp.uData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllLeadStatus() {
  try {
    const resp = await getLeadStatus();
    if (resp.status == 1) return resp.lsData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchFollowUp(data) {
  try {
    const resp = await getFollowUp(data);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchSetting() {
  try {
    const resp = await getSetting();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchsmstemplate() {
  try {
    const resp = await getAllSMSTemlate();
    if (resp.status == 1) return resp.Data;
    else return null;
  } catch {
    return null;
  }
}
//sakshi
export async function fetchRoleList() {
  try {
    const resp = await getAllRoleList();
    if (resp.status == 1) return resp.fData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchRolePermission(r_id) {
  try {
    const resp = await getRolePermission(r_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUserList(u_id, branch_id) {
  try {
    const resp = await getuserbyparentuser(u_id, branch_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUserPermission(u_id) {
  try {
    const resp = await getUserPermission(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUserbyType(type) {
  try {
    const resp = await getusersBytype(type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchManagerAllocationList() {
  try {
    const resp = await getmanagerallocationlist();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchBranchAllocationList(manager_id) {
  try {
    const resp = await getbranchallocatedlist(manager_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchETemplateCategory(id) {
  try {
    const resp = await getTemplateCategory(id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchSubCategory(lead_id) {
  try {
    const resp = await getTemplatesubCategory(lead_id);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchEmailTemplate() {
  try {
    const resp = await getEmailTemplate();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchBranchdata(branch_id) {
  try {
    const resp = await getBranchData(branch_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllTaskList(u_id, page, perPage) {
  try {
    const resp = await getAllTask(u_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchfeedback(t_id) {
  try {
    const resp = await getFeedback(t_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllTodayLeadFollowup(u_id) {
  try {
    const resp = await gettodayleadfollowup(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//tasknotification
export async function fetchAllNewTask(u_id) {
  try {
    const resp = await getnewTask({ u_id: u_id });
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function countAllTask(u_id, t_status) {
  try {
    const resp = await getcountAllTask(u_id, t_status);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function countAllLead(u_id, l_service) {
  try {
    const resp = await getcountAllLead(u_id, l_service);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function countAllTodayLead(u_id, l_service) {
  try {
    const resp = await getcountAllTodayLead(u_id, l_service);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}
// Customer
export async function fetchCustomerList(page, perPage, uid, bid) {
  try {
    const resp = await getCustomerList(page, perPage, uid, bid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUser() {
  try {
    const resp = await getUser();
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}
export async function fetchPayment(c_id) {
  try {
    const resp = await getPayment(c_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchCustomerbyid(id) {
  try {
    const resp = await getCustomerbyid(id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAttributesbyid(id) {
  try {
    const resp = await getAttributesbyid(id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

//visa
export async function fetchAllVisaList(u_id, branch_id, page, perPage) {
  try {
    const resp = await getAllVisaList(u_id, branch_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchVisabyid(id) {
  try {
    const resp = await getVisabyid(id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllAttestationList(u_id, branch_id, page, perPage) {
  try {
    const resp = await getAllAttestationList(u_id, branch_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchcomment(c_id) {
  try {
    const resp = await getcomment(c_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchcfiledata(c_id) {
  try {
    const resp = await getAllFiles(c_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchRefundAmt(c_id) {
  try {
    const resp = await getRefundAmt(c_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

//apostille
export async function fetchAllApostilleList(u_id, branch_id, page, perPage) {
  try {
    const resp = await getAllApostilleList(u_id, branch_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchApostillebyid(id) {
  try {
    const resp = await getApostillebyid(id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

//Translation

export async function fetchAllTranslationList(u_id, branch_id, page, perPage) {
  try {
    const resp = await getAllTranslationList(u_id, branch_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchTranslationbyid(id) {
  try {
    const resp = await getTranslationbyid(id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

//payment

export async function fetchPaymentList(page, perPage, u_id, branch_id) {
  try {
    const resp = await getPaymentList(page, perPage, u_id, branch_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//Refund Start
export async function fetchPaymentDetails(c_id) {
  try {
    const resp = await getPaymentDetails(c_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchrefunddata(u_id, page, perPage) {
  try {
    const resp = await getAllRefunddata(u_id, page, perPage);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchSettingdata(u_id) {
  try {
    const resp = await getsettingdata(u_id);
    if (resp) return resp;
    else return null;
  } catch {
    return null;
  }
}
//Refund End

//cashflow start
export async function fetchBranchUser(id) {
  try {
    const resp = await getBranchUser(id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}
export async function fetchCashPayment(u_id, b_id) {
  try {
    const resp = await getCashPayment(u_id, b_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//cashflow end

//Under process document list on dashboard
export async function fetchunderprocesslist(u_id, branch_id, user_type) {
  try {
    const resp = await getAllUnderProcesslist(u_id, branch_id, user_type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

//TDS
export async function fetchTDSList(formdata) {
  try {
    const resp = await getTDSList(formdata);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAttestationbyid(id) {
  try {
    const resp = await getAttestationbyid(id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

//Fetch Invoice Data
export async function fetchInvoiceData(cid, uid) {
  try {
    const resp = await fetchInvoiceDatabycid(cid, uid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//Fetch Branch Record By Branch Id
export async function fetchbranchdatabyid(bid) {
  try {
    const resp = await getbranchdatabyid(bid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//coverletter
export async function fetchBusinessSummary(page, perPage, uid, bid) {
  try {
    const resp = await getBusinessSummary(page, perPage, uid, bid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchRefundbyid(id, uid) {
  try {
    const resp = await getRefundbyid(id, uid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchpaymentdatabyid(id, bid, uid) {
  //08-07
  try {
    const resp = await getPaymentDatabyid(id, bid, uid); //08-07
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function getgraphleaddata(uid) {
  try {
    const resp = await fetchgraphleaddata(uid);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

//clientaccount
export async function fetchBranchCustomer(bid, uid, isSoloBranch) {
  try {
    const resp = await getBranchCustomer(bid, uid, isSoloBranch);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchcountFollowUp(data) {
  try {
    const resp = await getcountFollowUp(data);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//Fetch Proforma Invoice Data
export async function fetchProformaInvoiceData(lid, uid) {
  try {
    const resp = await fetchProformaInvoiceDatabylid(lid, uid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllEmailTemplate() {
  try {
    const resp = await getAllEmailTemplate();
    if (resp.status == 1) return resp.bData;
    else return null;
  } catch {
    return null;
  }
}

//////////////////////////////// customer /////////////////////////////////
// Customer
export async function fetchCustomers(m_c_id, page, perPage) {
  try {
    const resp = await getCustomers(m_c_id, page, perPage);
    if (resp.status == 1) return resp.cData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAttestations(m_c_id, page, perPage) {
  try {
    const resp = await getAttestations(m_c_id, page, perPage);
    if (resp.status == 1) return resp.cData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchApostilles(m_c_id, page, perPage) {
  try {
    const resp = await getApostilles(m_c_id, page, perPage);
    if (resp.status == 1) return resp.cData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchVisas(m_c_id, page, perPage) {
  try {
    const resp = await getVisas(m_c_id, page, perPage);
    if (resp.status == 1) return resp.cData;
    else return null;
  } catch {
    return null;
  }
}

//translationcust
export async function fetchTranslations(m_c_id, page, perPage) {
  try {
    const resp = await getTranslations(m_c_id, page, perPage);
    if (resp.status == 1) return resp.cData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchPayments(m_c_id, page, perPage) {
  try {
    const resp = await getPayments(m_c_id, page, perPage);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

//TDS
export async function fetchcustomerTDSList(m_c_id, page, perPage) {
  try {
    const resp = await getcustomerTDSList(m_c_id, page, perPage);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchCustomerFeedback(m_c_id, page, perPage) {
  try {
    const resp = await getCustomerFeedbacks(m_c_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchgoal() {
  try {
    const resp = await getAllGoal();
    if (resp.status == 1) return resp.Data;
    else return null;
  } catch {
    return null;
  }
}

//Goal on dashboard
export async function fetchgoallist(u_id) {
  try {
    const resp = await getAllGoallist(u_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

//Task on dashboard
export async function fetchAllDashTaskList(u_id) {
  try {
    const resp = await getAllDashTask(u_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

//Task User List
export async function fetchTaskUsers(branch_id, u_id) {
  try {
    const resp = await getTaskUsers(branch_id, u_id);
    if (resp.status == 1) return resp.uData;
    else return null;
  } catch {
    return null;
  }
}

//Fetch Manager Branch
export async function fetchManagerBranch(uid) {
  try {
    const resp = await fetchMangerwiseBranch(uid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//Fetch Branch User Data
export async function fetchBranchUserData(bid) {
  try {
    const resp = await fetchUserByBranch(bid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchactivitylogbyid(detail_id) {
  try {
    const resp = await getactivitylogbyid(detail_id);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchallholdapplication(u_id, branch_id) {
  try {
    const resp = await getallholdapplication(u_id, branch_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllDispatchingApllication(u_id, branch_id) {
  try {
    const resp = await getAllDispatchingApplications(u_id, branch_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchTemplatelistbycat(cid, scid, type, c_tax) {
  try {
    const resp = await gettemplatelistbycate(cid, scid, type, c_tax || 0);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchtemplatedata(etid) {
  try {
    const resp = await gettemplatedata(etid);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchUserById(u_id) {
  try {
    const resp = await getUserById(u_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchFaqCategory() {
  try {
    const resp = await getFaqCategory();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchFaq() {
  try {
    const resp = await getFaq();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchTarget() {
  try {
    const resp = await getTarget();
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUserTragetReport(u_id) {
  try {
    const resp = await getUserTaragetReport(u_id);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchDraftReceiptList(
  page,
  perPage,
  u_id,
  branch_id,
  pmode
) {
  try {
    const resp = await getDraftReceiptList(
      page,
      perPage,
      u_id,
      branch_id,
      pmode
    );
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchdraftpaymentdatabyid(id, bid, uid) {
  try {
    const resp = await getDraftPaymentDatabyid(id, bid, uid);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchsuggestion(u_id, user_type, branch_id) {
  try {
    const resp = await getAllSuggestion(u_id, user_type, branch_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUserMonthTragetReport(u_id) {
  try {
    const resp = await getUserMonthTaragetReport(u_id);
    return resp?.data;
  } catch {
    return null;
  }
}

export async function fetchmaincustomer(u_id, page, perPage) {
  try {
    const resp = await getAllmaincustomer(u_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchEmbassyFeeList(page, perPage) {
  try {
    const resp = await getEmbassyFeeList(page, perPage);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchCourierChargeList(page, perPage) {
  try {
    const resp = await getCourierChargeList(page, perPage);
    if (resp.status == 1) return resp.result;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllNewLead(u_id) {
  try {
    const resp = await getallnewlead(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllProcessOverdue(u_id, branch_id, user_type) {
  try {
    const resp = await getAllProcessOverdue(u_id, branch_id, user_type);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

//customerbranch
export async function fetchAllnewCustomerofAnotherBranch(u_id, branch_id) {
  try {
    const resp = await getAllnewCustomerofAnotherBranch(u_id, branch_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchFAQcatepermission(u_id) {
  try {
    const resp = await getFAQcatepermission(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllDraftreceipt(u_id, branch_id) {
  try {
    const resp = await getalldraftreceipt(u_id, branch_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllCashFlow() {
  try {
    const resp = await getAllCashFlow();
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllapplicants(m_c_id) {
  try {
    const resp = await getAllapplicants(m_c_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllInPickupList(u_id) {
  try {
    const resp = await getAllInPickupList(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllOutPickupList(u_id) {
  try {
    const resp = await getAllOutPickupList(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllPickupDoneList(u_id) {
  try {
    const resp = await getAllPickupDoneList(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllPickupDoneLeadList(u_id) {
  try {
    const resp = await getAllPickupDoneLeadList(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchQuestion(u_id) {
  try {
    const resp = await getallquestion(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchQuestionList(u_id, type) {
  try {
    const resp = await getAllQuestionlist(u_id, type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchuserschat(
  u_id,
  branch_id,
  user_type,
  is_marketing,
  marketing_parent_id
) {
  try {
    const resp = await getuserchat(
      u_id,
      branch_id,
      user_type,
      is_marketing,
      marketing_parent_id
    );
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchallcustomerchat(u_id, m_c_id, type, receiver_type) {
  try {
    const resp = await getallcustomerchat(u_id, m_c_id, type, receiver_type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchCustomerUser(sender_id, receiver_id) {
  try {
    const resp = await getCustomerUser(sender_id, receiver_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchtemplatebody(e_t_id, u_id) {
  try {
    const resp = await gettemplatebody(e_t_id, u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchEmailhistory(id, type) {
  try {
    const resp = await getEmailHistory(id, type);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchDraftReceiptCounter(u_id, branch_id, pmode) {
  try {
    const resp = await getDraftReceiptCounter(u_id, branch_id, pmode);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchservicevalue(country, type, stype) {
  try {
    const resp = await getservicevalue(country, type, stype);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchservicevalueType(id, country, stype, doc_type) {
  try {
    const resp = await getservicevalueType(id, country, stype, doc_type);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchBankDetails(user_type, branch_id) {
  try {
    const resp = await getBankDetails(user_type, branch_id);
    return resp.data;
  } catch {
    return null;
  }
}

export async function fetchreferralCustomers(m_c_id, page, perPage) {
  try {
    const resp = await getreferralcustomerlist(m_c_id, page, perPage);
    return resp;
  } catch {
    return null;
  }
}

export async function fetchattestationlistbycid(c_id, page, perPage) {
  try {
    const resp = await getAttestationListBycid(c_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchleadfollowup(l_id, c_id) {
  try {
    const resp = await getleadfollowup(l_id, c_id);
    if (resp.status == 1) return resp.data;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchUsersList(branch_id, u_id) {
  try {
    const resp = await getUserList(branch_id, u_id);
    if (resp.status == 1) return resp.uData;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllchat(u_id) {
  try {
    const resp = await getallchat(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchAllTodayCustomerFollowup(u_id) {
  try {
    const resp = await gettodaycustomerfollowup(u_id);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchcountCFollowUp(data) {
  try {
    const resp = await getcountCFollowUp(data);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchapostillelistbycid(c_id, page, perPage) {
  try {
    const resp = await getApostilleListBycid(c_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchtranslationlistbycid(c_id, page, perPage) {
  try {
    const resp = await getTranslationListBycid(c_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}

export async function fetchvisalistbycid(c_id, page, perPage) {
  try {
    const resp = await getVisaListBycid(c_id, page, perPage);
    if (resp.status == 1) return resp;
    else return null;
  } catch {
    return null;
  }
}
