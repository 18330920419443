import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilMoney,
  cilUser,
  cilFlightTakeoff,
  cilDescription,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

// const state = store.getState();
// var per_obj=state.common.user_permission;

const _nav = [
  {
    component: CNavGroup,
    name: "My Account",
    to: "/customer-panel/myaccount",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "My Services List",
        to: "/customer-panel/myaccount/myService",
      },
      {
        component: CNavItem,
        name: "My Feedbacks",
        to: "/customer-panel/myaccount/myFeedbacks",
      },
      {
        component: CNavItem,
        name: "Add Feedback",
        to: "/customer-panel/myaccount/addFeedbacks",
      },
      {
        component: CNavItem,
        name: "Mail History",
        to: "/customer-panel/myaccount/mailhistory",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Referral",
    to: "/customer-panel/referral",
    icon: <CIcon icon={cilFlightTakeoff} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Referral Value",
    to: "/customer-panel/referralcommision",
    icon: <CIcon icon={cilFlightTakeoff} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Chat",
    to: "/customer-panel/customerchat",
    icon: <CIcon icon={cilFlightTakeoff} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "All Process Status",
    to: "/customer-panel/allProcess",
    icon: <CIcon icon={cilFlightTakeoff} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: "Attestation Process Status",
  //   to: "/customer/attestationProcess",
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />
  // },
  // {
  //   component: CNavItem,
  //   name: "Apostille Process Status",
  //   to: "/customer/apostilleProcess",
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />
  // },
  //translationcust
  // {
  //   component: CNavItem,
  //   name: "Translation Process Status",
  //   to: "/customer/translationProcess",
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />
  // },
  {
    component: CNavGroup,
    name: "My Payments",
    to: "/customer-panel/mypayments",
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "My Payments",
        to: "/customer-panel/mypayments/myPayments",
      },
      {
        component: CNavItem,
        name: "My TDS",
        to: "/customer-panel/mypayments/myTDS",
      },
    ],
  },
  // {
  //   component: CNavItem,
  //   name: "Visa Process Status",
  //   to: "/customer/visaProcess",
  //   icon: <CIcon icon={cilFlightTakeoff} customClassName="nav-icon" />
  // },
];

export default _nav;
