import { notification } from "src/constants/message";
import moment from "moment";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { useState } from "react";
import { CButton, CFormInput, CRow, CCol } from "@coreui/react";
import "react-phone-number-input/style.css";

export const imageTypes = ["png", "jpg", "jpeg", "gif", "webp"];

export const compareById = (a, b) => {
  if (a.sequence === 0) {
    return 1; // Place 'a' at the end
  } else if (b.sequence === 0) {
    return -1; // Place 'b' at the end
  } else {
    return a.sequence - b.sequence; // Sort normally by ID
  }
};

export let showNotify = (status, message) => {
  let notifications = notification({
    type: status == 1 ? "success" : status == 2 ? "info" : "error",
    message: message || "Something went Wrong Please try Again",
  });
  notifications();
};

export const formatText = (message, selection, type) => {
  const newMessage = [
    message.slice(0, selection[0]),
    type == "b" ? "*" : type == "i" ? "_" : type == "s" ? "~" : "",
    message.slice(selection[0], selection[1]),
    type == "b" ? "*" : type == "i" ? "_" : type == "s" ? "~" : "",
    message.slice(selection[1]),
  ].join("");
  return newMessage;
};

export const sortByDate = async (data, field) =>
  data?.sort(
    (a, b) =>
      new Date(moment(a[field], "DD-MM-YYYY").format("YYYY-MM-DD")) -
      new Date(moment(b[field], "DD-MM-YYYY").format("YYYY-MM-DD"))
  );

export const showRequired = <span className="text-danger"> *</span>;

export const lightGreen = "#d5fdd9";
export const lightYellow = "#fbeeb8";
export const skyBlue = "#c1effe";
export const lightPurple = "#f7f4e4";

export const CountrySelect = ({ value, onChange, labels, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filteredCountries = getCountries().filter((country) => {
    const countryName = labels[country]?.toLowerCase() || "";
    const callingCode = getCountryCallingCode(country).toString();
    return (
      countryName.includes(searchTerm.toLowerCase()) ||
      callingCode.includes(searchTerm)
    );
  });

  const handleSelect = (country) => {
    onChange(getCountryCallingCode(country), country);
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  const handleClear = () => {
    onChange("");
    setSearchTerm("");
    setIsDropdownOpen(false);
  };

  return (
    <>
      <CRow>
        <CCol md={10}>
          <div style={{ position: "relative", width: "100%" }} {...rest}>
            <CFormInput
              type="text"
              value={
                value
                  ? `${labels[value]} +${getCountryCallingCode(value)}`
                  : searchTerm
              }
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by country name or code"
              style={{
                width: "100%",
                padding: "6px",
                boxSizing: "border-box",
                marginLeft: "4px",
                marginRight: "4px",
              }}
              inputMode="search"
            />
            {isDropdownOpen && (
              <ul
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  maxHeight: "200px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  background: "#fff",
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  zIndex: 10,
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                {!searchTerm && (
                  <li
                    onClick={() => handleSelect("")}
                    style={{ padding: "8px", cursor: "pointer" }}
                  >
                    {labels["ZZ"]}
                  </li>
                )}

                {filteredCountries.map((country) => (
                  <li
                    key={country}
                    onClick={() => handleSelect(country)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    {labels[country]} +{getCountryCallingCode(country)}
                  </li>
                ))}

                {filteredCountries.length === 0 && (
                  <li style={{ padding: "10px", color: "#888" }}>
                    No results found
                  </li>
                )}
              </ul>
            )}
          </div>
        </CCol>

        <CCol md={1} className="mr-2">
          <CButton color="secondary" onClick={handleClear}>
            X
          </CButton>
        </CCol>
      </CRow>
    </>
  );
};

export const CountryInquirySelect = ({
  value,
  onChange,
  labels,
  type,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filteredCountries = getCountries().filter((country) => {
    const countryName = labels[country]?.toLowerCase() || "";
    const callingCode = getCountryCallingCode(country).toString();
    return (
      countryName.includes(searchTerm.toLowerCase()) ||
      callingCode.includes(searchTerm)
    );
  });

  const handleSelect = (country) => {
    onChange(getCountryCallingCode(country), country);
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  const handleClear = () => {
    onChange("");
    setSearchTerm("");
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%" }} {...rest}>
        <CFormInput
          type="text"
          value={
            value
              ? `${labels[value]} +${getCountryCallingCode(value)}`
              : searchTerm
          }
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by country name or code"
          style={{
            width: "100%",
            padding: "16px",
            boxSizing: "border-box",
            border: `1px solid ${
              type === "portrait2" || type === "portrait3"
                ? "#b2b2b2"
                : "#998E81"
            }`,
            borderRadius:
              type === "portrait2"
                ? "15px"
                : type === "portrait3"
                ? "45px"
                : "4px",
            position: "relative",
          }}
          inputMode="search"
        />
        {value && (
          <button
            type="button"
            className="me-1"
            onClick={handleClear}
            style={{
              position: "absolute",
              top: "50%",
              right: "-6px",
              transform: "translateY(-50%)",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              color: "#000",
            }}
          >
            ✕
          </button>
        )}
        {isDropdownOpen && (
          <ul
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              maxHeight: "200px",
              overflowY: "auto",
              border: "1px solid #ccc",
              background: "#fff",
              listStyle: "none",
              margin: 0,
              padding: 0,
              zIndex: 10,
              marginLeft: "4px",
              marginRight: "4px",
            }}
          >
            {!searchTerm && (
              <li
                onClick={() => handleSelect("")}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                {labels["ZZ"]}
              </li>
            )}

            {filteredCountries.map((country) => (
              <li
                key={country}
                onClick={() => handleSelect(country)}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  borderBottom: "1px solid #f0f0f0",
                }}
              >
                {labels[country]} +{getCountryCallingCode(country)}
              </li>
            ))}

            {filteredCountries.length === 0 && (
              <li style={{ padding: "10px", color: "#888" }}>
                No results found
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  );
};
