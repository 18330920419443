import Request from ".";
import { Routes } from "../constants/api_url";

const LoginRequest = async (formdata) => {
  try {
    const res = await Request.post(Routes.LOGIN, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const SendOtp = async (formdata) => {
  try {
    const res = await Request.post(Routes.EmailOtp, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const CustomerLoginRequest = async (formdata) => {
  try {
    const res = await Request.post(Routes.CUSTOMERLOGIN, formdata, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export { LoginRequest, CustomerLoginRequest, SendOtp };
