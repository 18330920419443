import React, { Component, useEffect } from "react";
import {
  Navigate,
  BrowserRouter,
  Route,
  Routes as NewRoutes,
} from "react-router-dom";
import "./scss/style.scss";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "src/store/actions/commonActions";
import Dashboard from "./views/dashboard/Dashboard";
import CustomerLogin from "./views/pages/CustomerView/CustomerLogin/CustomerLogin";
import CustomerLayout from "./views/pages/CustomerView/Customerlayout/customerLayout";
import InquiryForm from "./views/pages/InquiryForm/inquiryform";
import InquiryWebsiteLandscape1 from "./views/pages/InquiryForm/inquiryWebsiteLandscape1";
import InquiryWebsiteLandscape2 from "./views/pages/InquiryForm/inquiryWebsiteLandscape2";
import InquiryWebsitePortrait1 from "./views/pages/InquiryForm/inquiryWebsitePortrait1";
import InquiryWebsitePortrait2 from "./views/pages/InquiryForm/inquiryWebsitePortrait2";
import InquiryWebsitePortrait3 from "./views/pages/InquiryForm/inquiryWebsitePortrait3";
import ReferralForm from "./views/pages/ReferralForm/referralform";
import moment from "moment";
import { usercheck } from "src/api/commonapi";
import PickupDoneForm from "./views/pages/Lead/pickupDoneForm";
import PickupOutDoneForm from "./views/pages/Lead/PickupOutDoneForm";
import "./App.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/Forgot_Password/forgotpassword")
);

const Login = React.lazy(() => import("./views/pages/login/Login"));

const App = () => {
  const dispatch = useDispatch();

  const { user_data } = useSelector((state) => state.common);

  let isLocalStorageAvailable = false;
  let customerData = null;
  try {
    isLocalStorageAvailable =
      typeof window !== "undefined" && window?.localStorage;
    customerData = localStorage.getItem("customerData");
  } catch {
    isLocalStorageAvailable = false;
  }

  const restoreUser = async () => {
    let users;
    try {
      isLocalStorageAvailable =
        typeof window !== "undefined" && window?.localStorage;
      users = await localStorage.getItem("userData");
    } catch {
      isLocalStorageAvailable = false;
    }
    if (users == null) {
    } else {
      let user = JSON.parse(users);
      const resp = await usercheck(user.u_id);

      if (
        user?.currDate != moment().format("DD/MM/YYYY") ||
        resp?.status == 0
      ) {
        localStorage.removeItem("userData");
        localStorage.removeItem("managerData");
        localStorage.removeItem("sbranch_id");
        localStorage.removeItem("ncurrent_branchid");
        localStorage.removeItem("documentlist");
        localStorage.removeItem("modalData");
        setUserData(dispatch, null);
        window.location.reload();
      } else {
        setUserData(dispatch, users);
      }
    }
  };

  useEffect(() => {
    restoreUser();
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <NewRoutes>
          <Route
            path="/pickupoutdone"
            name="Pickup Out Done"
            element={<PickupDoneForm />}
          />
          <Route
            path="/pickupouttodone"
            name="Pickup Out Done"
            element={<PickupOutDoneForm />}
          />
          {user_data == null && customerData == null ? (
            <>
              <Route
                exact
                path="/customer-panel/*"
                name="Login Page"
                element={<CustomerLogin />}
              />
              <Route path="/*" name="Login Page" element={<Login />} />
              <Route
                path="/forgotpassword"
                name="ForgotPassword Page"
                element={<ForgotPassword />}
              />
              <Route
                path="/inquiry-form"
                name="Inquiry Form"
                element={<InquiryForm />}
              />
              <Route
                path="/inquiry-form/:id"
                name="Inquiry Form"
                element={<InquiryForm />}
              />
              <Route
                path="/inquiryform/:type/:id"
                name="Inquiry Form"
                element={<InquiryForm />}
              />
              <Route
                path="/inquiry-landscape1/:id"
                name="Inquiry Form Landscape1"
                element={<InquiryWebsiteLandscape1 />}
              />
              <Route
                path="/inquirylandscape1/:type/:id"
                name="Inquiry Form Landscape1"
                element={<InquiryWebsiteLandscape1 />}
              />
              <Route
                path="/inquiry-landscape2/:id"
                name="Inquiry Form Landscape2"
                element={<InquiryWebsiteLandscape2 />}
              />
              <Route
                path="/inquirylandscape2/:type/:id"
                name="Inquiry Form Landscape2"
                element={<InquiryWebsiteLandscape2 />}
              />
              <Route
                path="/inquiry-portrait1/:id"
                name="Inquiry Form Portrait1"
                element={<InquiryWebsitePortrait1 />}
              />
              <Route
                path="/inquiryportrait1/:type/:id"
                name="Inquiry Form Portrait1"
                element={<InquiryWebsitePortrait1 />}
              />
              <Route
                path="/inquiry-portrait2/:id"
                name="Inquiry Form Portrait2"
                element={<InquiryWebsitePortrait2 />}
              />
              <Route
                path="/inquiryportrait2/:type/:id"
                name="Inquiry Form Portrait2"
                element={<InquiryWebsitePortrait2 />}
              />
              <Route
                path="/inquiry-portrait3/:id"
                name="Inquiry Form Portrait3"
                element={<InquiryWebsitePortrait3 />}
              />
              <Route
                path="/inquiryportrait3/:type/:id"
                name="Inquiry Form Portrait3"
                element={<InquiryWebsitePortrait3 />}
              />
              <Route
                path="/referral-form/:cid/:crid"
                name="Referral Form"
                element={<ReferralForm />}
              />
            </>
          ) : user_data ? (
            <Route path="/*" name="Home" element={<DefaultLayout />} />
          ) : (
            <Route
              exact
              path="/customer-panel/*"
              name="Home"
              element={<CustomerLayout />}
            />
          )}
        </NewRoutes>
      </React.Suspense>
    </BrowserRouter>
  );
};
export default App;
