import Axios from "axios";
import { Routes } from "../constants/api_url";
export const axios = Axios.create({
  baseURL: Routes.API_HOSTNAME,
  timeout: 1000000000,
  responseType: "json",
});
let user = JSON.parse(localStorage.getItem("userData"));
let customerData = JSON.parse(localStorage.getItem("customerData"));
axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      current_u_id: user?.u_id,
      current_branch_id: user?.branch_id,
      transfer_dispatching_branch: user?.transfer_dispatching_branch,
      transfer_dispatching_user: user?.transfer_dispatching_user,
      current_u_type: user?.user_type,
      is_solo_branch: user?.is_solo_branch,
    };
    if (user?.auth_token) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${user?.auth_token}`;
      config.headers.type = localStorage.getItem("user_type");
    } else if (customerData?.auth_token) {
      config.headers.authorization = `Bearer ${customerData?.auth_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
