import { StoreAction } from "src/constants";

const initialState = {
  user_data: null,
  loading: false,
  user_permission: null,
  tpermission: null,
  tcounter: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_USER_DATA:
      return {
        ...state,
        user_data: action.data,
      };
    case StoreAction.SET_USER_PERMISSION:
      return {
        ...state,
        user_permission: action.data,
      };
    case StoreAction.SET_TEMPLATE_PERMISSION:
      return {
        ...state,
        tpermission: action.data,
      };
    case StoreAction.SET_TOTAL_FOLLOWUP:
      return {
        ...state,
        tcounter: action.data,
      };
    default:
      return state;
  }
}
