import React from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import avatar8 from "src/assets/images/avatars/8.jpg";
import userImage from "src/assets/images/avatars/user.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AppHeaderDropdown = () => {
  let user = JSON.parse(localStorage.getItem("customerData"));
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = async () => {
    await localStorage.removeItem("customerData");
    // setUserData(dispatch, null);
    // setUserPermission(dispatch,null);
    // navigate("/customer" , {replace:true});
    // window.location.reload();
    window.location.href = "/customer-panel";
  };
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={userImage} size="md"></CAvatar>
        {/* <CIcon icon={cilUser} size="md" className="me-2" /> */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem href="/customer-panel/changepassword">
          <CIcon icon={cilUser} className="me-2" />
          Change Password
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#" onClick={logout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
