import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { notification } from "src/constants/message";
import en from "react-phone-number-input/locale/en";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
  CCardGroup,
  CModal,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormCheck,
  CTableDataCell,
} from "@coreui/react";
import { fetchUserById } from "src/api/common";
import { insertLead } from "src/api/commonapi";

import "./inquiryform.css";
import { Routes } from "src/constants/api_url";
import CustomImage from "src/views/CommonFunctions/CustomImage";
import { CountryInquirySelect } from "src/views/CommonFunctions/common";
//-----------------------------------------------------------------------

const InquiryWebsitePortrait2 = () => {
  const [userData, setUserData] = useState([]);
  const [formdata, setformdata] = useState({});
  const [sresp, setresp] = useState(0);
  const [step, setStep] = useState(0);
  const [submitResponse, setSubmitResponse] = useState(0);
  const [existingLeadInsertModal, setExistingLeadInsertModal] = useState({
    visible: false,
    data: {},
  });
  let serviceOption = [
    { value: 10, label: "Attestation" },
    { value: 17, label: "Apostille" },
    { value: 20, label: "Translation" }, //translation
    { value: 8, label: "Visa" },
  ];

  let { id, type } = useParams();
  let u_id = 0;
  let p_u_id = 0;
  let date = moment().format("DD-MM-YYYY");
  if (id != undefined) {
    u_id = Buffer.from(id, "base64").toString("ascii");
  }

  useEffect(async () => {
    let resp = await fetchUserById(u_id);
    // console.log("resp", resp);
    setUserData(resp);
    setStep(0);
    setformdata({
      l_date: date,
      priority: 2,
      l_source: resp?.is_marketing == 1 ? 271 : 599,
      l_status: 1,
      l_branch_id: resp?.branch_id,
      l_created_by:
        resp?.marketing_parent_id == 0 ? u_id : resp?.marketing_parent_id,
      l_created_branch_id: resp?.branch_id,
      l_allocate_to:
        resp?.marketing_parent_id == 0 ? u_id : resp?.marketing_parent_id,
      reference_id: resp?.is_marketing == 1 ? u_id : 0,
      l_name: "",
      co_no: "",
      l_email: "",
      l_address: "",
      l_city: "",
      l_service: type ? type : 0,
      l_name_prefix: ":",
      f_url: resp?.f_url,
      country_code: "IN",
      countryCode: 91,
    });
  }, [sresp]);

  const submitData = async (e) => {
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formdata.co_no.match(phoneRegex)) {
      let notify = notification({
        type: "error",
        message: "Please enter a valid 10-digit mobile number.",
      });
      notify();
    } else if (!formdata.l_email.match(emailRegex)) {
      let notify = notification({
        type: "error",
        message: "Please enter a valid email address.",
      });
      notify();
    } else if (
      formdata.l_name == undefined ||
      formdata.l_name == "" ||
      formdata.co_no == undefined ||
      formdata.co_no == "" ||
      formdata.l_email == undefined ||
      formdata.l_email == "" ||
      formdata.l_city == undefined ||
      formdata.l_city == "" ||
      formdata.l_service == undefined ||
      formdata.l_service == 0 ||
      formdata.l_service == undefined ||
      formdata.l_service == -1
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill All Data",
      });
      notify();
    } else {
      setStep(1);
      const resp = await insertLead(JSON.stringify(formdata));
      if (resp.status == 0) {
        let notify = notification({
          type: "error",
          message: resp.message,
        });
        notify();
        setStep(0);
      }
      if (resp.status == 1 || resp.status == 4) {
        setSubmitResponse(1);
        setStep(0);
        setformdata({});
        if (
          formdata?.f_url != undefined &&
          formdata?.f_url != null &&
          formdata?.f_url != ""
        ) {
          window.location.replace(`${formdata.f_url}`);
        }
        setresp(sresp + 1);
      }
      if (resp.status == 2) {
        setStep(0);
        setExistingLeadInsertModal({
          visible: true,
          data: {
            assignee_no: resp?.assignee_no,
            assignee_email: resp?.assignee_email,
          },
        });
      }
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "8px",
      border: "1px solid #f8f8f8",
    }),
    menu: (provided) => ({
      ...provided,
      padding: "5px",
    }),
  };

  return (
    <>
      <div className="d-flex flex-row bg-container">
        <CContainer>
          {submitResponse == 0 ? (
            <>
              <div>
                <div
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <CForm className="p-1">
                    <CRow className="mb-2 pt-3">
                      <CCol md={12} xs={12} className="px-1">
                        <CFormInput
                          type="text"
                          style={{
                            border: "1px solid #b2b2b2",
                            borderRadius: "15px",
                          }}
                          className="p-3"
                          value={formdata?.l_name}
                          id="exampleFormControlInput1"
                          name="l_name"
                          placeholder="Name"
                          onChange={(e) => {
                            setformdata({
                              ...formdata,
                              l_name: e.target.value,
                            });
                          }}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mb-2">
                      <CCol md={5} xs={5} className="px-1">
                        <CountryInquirySelect
                          labels={en}
                          value={formdata?.country_code}
                          onChange={(countryCode, country) => {
                            setformdata((prevData) => ({
                              ...prevData,
                              country_code: country,
                              countryCode: countryCode,
                              co_no: "",
                            }));
                          }}
                          type="portrait2"
                        />
                      </CCol>
                      <CCol md={7} xs={7} className="px-1">
                        <CFormInput
                          type="text"
                          style={{
                            border: "1px solid #b2b2b2",
                            borderRadius: "15px",
                          }}
                          className="p-3"
                          value={formdata?.co_no}
                          id="exampleFormControlInput1"
                          name="co_no"
                          placeholder="Mobile"
                          onChange={(e) => {
                            let sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (
                              formdata?.country_code == "IN" &&
                              sanitizedValue.length > 10
                            ) {
                              sanitizedValue = sanitizedValue.slice(0, 10);
                            }
                            setformdata({
                              ...formdata,
                              co_no: sanitizedValue,
                            });
                          }}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mb-2">
                      <CCol md={12} xs={12} className="px-1">
                        <CFormInput
                          type="email"
                          style={{
                            border: "1px solid #b2b2b2",
                            borderRadius: "15px",
                          }}
                          className="p-3"
                          value={formdata?.l_email}
                          id="exampleFormControlInput1"
                          name="l_email"
                          placeholder="Email"
                          onChange={(e) => {
                            setformdata({
                              ...formdata,
                              l_email: e.target.value,
                            });
                          }}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mb-2">
                      <CCol md={12} xs={12} className="px-1">
                        <CFormInput
                          value={formdata?.l_city}
                          type="text"
                          style={{
                            border: "1px solid #b2b2b2",
                            borderRadius: "15px",
                          }}
                          className="p-3"
                          id="exampleFormControlInput1"
                          name="l_city"
                          placeholder="City"
                          onChange={(e) => {
                            setformdata({
                              ...formdata,
                              l_city: e.target.value,
                            });
                          }}
                        />
                      </CCol>
                    </CRow>
                    {type === undefined && (
                      <CRow className="mb-2 px-1 pt-0">
                        <CTableDataCell
                          style={{
                            border: "1px solid #b2b2b2",
                            borderRadius: "15px",
                            color: "#8a93a2",
                            background: "#fff",
                          }}
                        >
                          <CRow className="d-flex align-items-center">
                            <CCol xs={12} sm={3} className="p-2">
                              <CFormLabel
                                className="p-1"
                                style={{ color: "#8a93a2" }}
                              >
                                Select Service
                              </CFormLabel>
                            </CCol>
                            <CCol xs={12} sm={9} className="p-1">
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="attestation"
                                label="Attestation"
                                checked={formdata.l_service == 10}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 10,
                                  });
                                }}
                              />
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="apostille"
                                label="Apostille"
                                checked={formdata.l_service == 17}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 17,
                                  });
                                }}
                              />
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="translation"
                                label="Translation"
                                checked={formdata.l_service == 20}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 20,
                                  });
                                }}
                              />
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="visa"
                                label="Visa"
                                checked={formdata.l_service == 8}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 8,
                                  });
                                }}
                              />
                            </CCol>
                          </CRow>
                        </CTableDataCell>
                      </CRow>
                    )}
                    <CRow className="mb-2">
                      <CCol xs={12} className="px-1">
                        <CFormTextarea
                          style={{
                            border: "1px solid #b2b2b2",
                            borderRadius: "12px",
                            paddingTop: "18px",
                          }}
                          value={formdata?.l_message}
                          type="text"
                          id="exampleFormControlInput1"
                          name="l_message"
                          placeholder="Message"
                          onChange={(e) => {
                            setformdata({
                              ...formdata,
                              l_message: e.target.value,
                            });
                          }}
                          rows={6}
                        />
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol
                        className="px-1"
                        xs={12}
                        md={12}
                        style={{ display: "grid" }}
                      >
                        <CButton
                          disabled={step === 0 ? false : true}
                          style={{
                            marginBottom: "25px",
                            background: "#ee1c25",
                            fontWeight: 600,
                            padding: "17px 50px",
                            border: "none",
                            borderRadius: "12px",
                          }}
                          className="submit-button"
                          onClick={submitData}
                        >
                          Send
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </div>
            </>
          ) : (
            <>
              <CRow className="justify-content-center">
                <CCol md={5} style={{ width: "600px" }}>
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <>
                          <CForm
                            className="dsgrid"
                            // noValidate
                            // validated={validated}
                            // onSubmit={handleSubmit}
                            // onSubmit={handleSubmit(onSubmit)}
                          >
                            <CustomImage
                              src={`${Routes.API_HOSTNAME}/images/adminlogo/check.png`}
                              className="mb-3 center_img"
                            />
                            <h2 className="text-center mb-3">Thank You !</h2>
                            <h5 className="text-center mb-3">
                              Your submission has been sent.
                            </h5>
                            <a
                              style={{ cursor: "pointer" }}
                              className="text-center"
                              color="link"
                              onClick={() => {
                                setSubmitResponse(0);
                              }}
                            >
                              Go Back
                            </a>
                          </CForm>
                        </>
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </>
          )}
          <CModal
            backdrop="static"
            alignment="center"
            visible={existingLeadInsertModal.visible}
            onClose={() =>
              setExistingLeadInsertModal({
                ...existingLeadInsertModal,
                visible: false,
              })
            }
            style={{ borderRadius: 10 }}
          >
            <div
              className="d-flex justify-content-center p-3"
              style={{
                backgroundColor: "#005e9e",
                borderBottom: "1px solid #d7d7d7",
                color: "white",
                textAlign: "center",
              }}
            >
              <CModalTitle>YOU HAVE ALREADY SUBMITTED QUERY</CModalTitle>
            </div>
            <CModalBody
              style={{
                color: "black",
                background: "#ededed",
                borderRadius: "10px",
                lineHeight: "25px",
              }}
            >
              <div className="mb-2 text-center">
                {" "}
                For more Inquiry, <br />
                Please call{" "}
                <span style={{ color: "#cf2e2e" }}>
                  {existingLeadInsertModal.data.assignee_no}
                </span>{" "}
                <br /> Or <br /> Reply to the previous email conversation at{" "}
                <br />
                <span style={{ color: "#cf2e2e" }}>
                  {existingLeadInsertModal.data.assignee_email}
                </span>{" "}
              </div>
            </CModalBody>
            <CModalFooter
              className="d-flex justify-content-center"
              style={{ background: "#cccccc" }}
            >
              <CButton
                className=""
                style={{
                  marginBottom: "6px",
                  marginTop: "6px",
                  background: "#0562a8",
                  color: "#ffffff",
                  fontWeight: 600,
                  padding: "10px 22px",
                  border: "none",
                  borderRadius: "18px",
                }}
                onClick={() => {
                  setExistingLeadInsertModal({
                    ...existingLeadInsertModal,
                    visible: false,
                  });
                }}
              >
                Ok
              </CButton>
            </CModalFooter>
          </CModal>
        </CContainer>
      </div>
    </>
  );
};

export default InquiryWebsitePortrait2;
